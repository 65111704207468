import type { Spec } from 'vega';

import { map } from '@stlc/lodash';
import { pitchTypes } from '@stlc/lookup/statcast';

export const pitchMovementSpec: Spec = {
    autosize: 'fit',
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    width: 280,
    height: 280,
    usermeta: { resize: false },
    background: 'transparent',
    config: {
        title: {
            font: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            fontSize: 16,
            fontWeight: 'normal',
            color: '#333333',
        },
        axis: {
            domainColor: '#aaaaaa',
            domainOpacity: 1,
            gridColor: '#dddddd',
            gridOpacity: 1,
            labelColor: '#333333',
            labelFont: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            labelFontSize: 11,
            labelFontWeight: 'normal',
            tickColor: '#999999',
            titleFont: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            titleFontSize: 16,
            titleFontWeight: 'normal',
            titleColor: '#333333',
            titlePadding: 16,
        },
        text: {
            font: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
        },
        events: { defaults: { allow: ['wheel'] } },
    },
    signals: [
        { name: 'height', update: 'width' },
        { name: 'minX', value: -24 },
        { name: 'maxX', value: 24 },
        { name: 'chartScale', update: 'min(max(width / 400, 0.75), 1.25)' },
        { name: 'pitcherThrows', value: null },
        { name: 'perspective', value: 'pitcher' },
        {
            name: 'reverseArmGloveLabel',
            update: "((pitcherThrows === 'Left' || pitcherThrows === 'L') && perspective == \"batter\") || (pitcherThrows !== 'Left' && pitcherThrows !== 'L' && perspective == \"pitcher\")",
        },
        { name: 'showLeague', value: true },
        { name: 'showAverage', value: true },
        { name: 'pitchType', value: [] },
        { name: 'unit', value: 'inches' },
        { name: 'brushable', value: false },
        { name: 'selectable', value: true },
        { name: 'isInteractive', update: '!brushable && selectable' },
        { name: 'hoveredId', value: null },
        {
            name: 'hovered',
            value: null,
            on: [
                { events: '@visibleMarks:mouseover', update: '!brushed && group().datum ? group().datum.id : null' },
                { events: '@visibleMarks:mouseout', update: 'null' },
            ],
        },
        { name: 'filteredIds', value: null },
        { name: 'selectedIds', value: null },
        { name: 'insufficientData', update: "length(data('data')) === 0" },
        {
            name: 'gScale',
            value: {
                field: 'pitchType',
                label: {
                    name: 'pitchTypeAbbrev',
                    type: 'ordinal',
                    domain: map(pitchTypes, 'name'),
                    range: map(pitchTypes, (datum) => datum.abbrev || datum.value),
                },
                color: {
                    name: 'pitchTypeColor',
                    type: 'ordinal',
                    domain: map(pitchTypes, 'name'),
                    range: map(pitchTypes, 'color'),
                },
            },
        },
        {
            name: 'showPitchNumbers',
            value: false,
        },
        {
            name: 'actualPitchSize',
            value: false,
        },
        {
            name: 'pitchSize',
            update: 'max(PI * pow((width / ((maxX / 12) - (minX / 12))) * (0.12 / (showPitchNumbers || actualPitchSize ? 1 : 1.5)), 2), 32)',
        },
        {
            name: 'selectedId',
            value: null,
            on: [
                {
                    events: [
                        {
                            merge: [
                                {
                                    type: 'mousedown',
                                    markname: 'visibleMarks',
                                },
                                {
                                    type: 'touchstart',
                                    markname: 'visibleMarks',
                                },
                            ],
                        },
                    ],
                    update: 'group().datum ? group().datum.id : null',
                    force: true,
                },
            ],
        },
        {
            name: 'brushStart',
            value: null,
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                        },
                        {
                            type: 'touchstart',
                            consume: true,
                        },
                    ],
                    update: 'brushable ? { x: clamp(x(), 0, width), y: clamp(y(), 0, height) } : null',
                },
            ],
        },
        {
            name: 'brushEnd',
            value: {},
            on: [
                {
                    events: 'mousedown, [mousedown, window:mouseup] > window:mousemove, touchstart, [touchstart, window:touchend] > window:touchmove',
                    update: 'brushable ? { x: clamp(x(), 0, width), y: clamp(y(), 0, height) } : {}',
                },
            ],
        },
        {
            name: 'brushed',
            value: false,
            on: [
                {
                    events: [{ type: 'mousedown' }, { type: 'touchstart' }],
                    update: '!selectable && brushable ? true : false',
                },
                { events: 'window:mouseup, window:touchend', update: 'false' },
            ],
        },
        {
            name: 'brushX',
            update: `brushStart ? extent(invert('x', [brushStart.x, brushEnd.x])) : []`,
        },
        {
            name: 'brushY',
            update: `brushStart ? extent(invert('y', [brushStart.y, brushEnd.y])) : []`,
        },
        {
            name: 'brushOperation',
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                        },
                        {
                            type: 'touchstart',
                            consume: true,
                        },
                    ],
                    update: "event.shiftKey && !event.altKey ? 'add' : event.altKey && !event.shiftKey ? 'remove' : null",
                },
            ],
        },
        { name: 'brushedIds', value: null, react: true },
        { name: 'hasSelected', update: '(selectedIds && length(selectedIds)) || (brushedIds && length(brushedIds))' },
        { name: 'currentId', value: null },
    ],
    data: [
        {
            name: 'data',
            transform: [
                { type: 'filter', expr: 'datum.pitchType' },
                {
                    type: 'filter',
                    expr: 'datum.inducedVerticalBreak && datum.horizontalBreak',
                },
                {
                    type: 'formula',
                    as: 'inducedVerticalBreak',
                    expr: "datum.inducedVerticalBreak * (unit === 'feet' ? 12 : 1)",
                },
                {
                    type: 'formula',
                    as: 'horizontalBreak',
                    expr: "datum.horizontalBreak * (unit === 'feet' ? 12 : 1)",
                },
            ],
            values: [],
        },
        {
            name: 'visibleData',
            source: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: '!filteredIds || (filteredIds && length(filteredIds) > 0 && indexof(filteredIds, datum.id) >= 0)',
                },
            ],
        },
        {
            name: 'hiddenData',
            source: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: 'filteredIds && (length(filteredIds) == 0 || indexof(filteredIds, datum.id) == -1)',
                },
            ],
        },
        {
            name: 'summaryData',
            source: 'visibleData',
            transform: [
                {
                    type: 'filter',
                    expr: "datum.pitchType !== 'Unknown' && datum.pitchType !== 'Unknown' && ((selectedIds && indexof(selectedIds, datum.id) !== -1) || !selectedIds)",
                },
                {
                    type: 'aggregate',
                    groupby: ['pitchType'],
                    fields: [
                        'inducedVerticalBreak',
                        'inducedVerticalBreak',
                        'inducedVerticalBreak',
                        'horizontalBreak',
                        'horizontalBreak',
                        'horizontalBreak',
                    ],
                    ops: ['min', 'max', 'mean', 'min', 'max', 'mean'],
                    as: [
                        'inducedVerticalBreak0',
                        'inducedVerticalBreak1',
                        'inducedVerticalBreak',
                        'horizontalBreak0',
                        'horizontalBreak1',
                        'horizontalBreak',
                    ],
                },
                // {
                //     type: 'lookup',
                //     from: 'lgData',
                //     key: 'pitchType',
                //     fields: ['pitchType'],
                //     values: ['ellipseData'],
                //     as: ['lgData'],
                //     default: [],
                // },
            ],
        },
        {
            name: 'lgData',
            values: [],
            transform: [
                {
                    type: 'filter',
                    expr: "indata('summaryData', 'pitchType', datum.pitchType)",
                },
            ],
        },
        {
            name: 'brushedData',
            source: 'visibleData',
            transform: [
                {
                    type: 'filter',
                    expr: 'isDefined(datum.horizontalBreak) && isDefined(datum.inducedVerticalBreak)',
                },
                {
                    type: 'filter',
                    expr: 'inrange(datum.horizontalBreak, brushX) && inrange(datum.inducedVerticalBreak, brushY)',
                },
            ],
        },
    ],
    scales: [
        {
            name: 'x',
            type: 'linear',
            domain: [-24, 24],
            range: 'width',
        },
        {
            name: 'y',
            type: 'linear',
            domain: [-24, 24],
            nice: true,
            zero: true,
            range: 'height',
        },
        {
            name: 'xMarkScale',
            type: 'linear',
            domain: [-24, 24],
            range: 'width',
            reverse: { signal: 'perspective === "batter"' },
        },
        {
            name: 'yMarkScale',
            type: 'linear',
            domain: [-24, 24],
            nice: true,
            zero: true,
            range: 'height',
        },
        {
            name: 'pitchColor',
            type: 'ordinal',
            domain: map(pitchTypes, 'name'),
            range: map(pitchTypes, 'color'),
        },
        {
            name: 'gColor',
            type: 'ordinal',
            domain: { signal: 'gScale.color.domain' },
            range: { signal: 'gScale.color.range' },
        },
        {
            name: 'gLabel',
            type: 'ordinal',
            domain: { signal: 'gScale.label.domain' },
            range: { signal: 'gScale.label.range' },
        },
    ],
    marks: [
        {
            type: 'text',
            encode: {
                enter: {
                    fillOpacity: { value: 0.8 },
                    align: { value: 'right' },
                    baseline: { value: 'bottom' },
                    angle: { value: -90 },
                },
                update: {
                    fontSize: { signal: '14 * chartScale' },
                    text: { signal: "stlcTranslate('chart:rise_label')" },
                    x: { value: 0, scale: 'xMarkScale', offset: { value: -4 } },
                    y: { value: 25, scale: 'yMarkScale' },
                },
            },
        },
        {
            type: 'text',
            encode: {
                enter: {
                    fillOpacity: { value: 0.8 },
                    align: { value: 'right' },
                    baseline: { value: 'bottom' },
                    angle: { value: 90 },
                },
                update: {
                    fontSize: { signal: '14 * chartScale' },
                    text: { signal: "stlcTranslate('chart:drop_label')" },
                    x: { value: 0, scale: 'xMarkScale', offset: { value: 4 } },
                    y: { value: -25, scale: 'yMarkScale' },
                },
            },
        },
        {
            type: 'text',
            encode: {
                enter: {
                    fillOpacity: { value: 0.8 },
                    baseline: { value: 'top' },
                },
                update: {
                    x: [
                        {
                            test: 'reverseArmGloveLabel',
                            value: 24,
                            scale: 'x',
                        },
                        { value: -24, scale: 'x' },
                    ],
                    y: { value: 0, scale: 'yMarkScale', offset: { value: 4 } },
                    fontSize: { signal: '14 * chartScale' },
                    text: {
                        signal: "pitcherThrows == null ? stlcTranslate('chart:thirdBase_label', { defaultText: '3B' }) : stlcTranslate('chart:armside_label')",
                    },
                    align: [{ test: 'reverseArmGloveLabel', value: 'right' }, { value: 'left' }],
                },
            },
        },
        {
            type: 'text',
            encode: {
                enter: {
                    fillOpacity: { value: 0.8 },
                    baseline: { value: 'bottom' },
                },
                update: {
                    x: [
                        { test: 'reverseArmGloveLabel', value: -24, scale: 'x' },
                        { value: 24, scale: 'x' },
                    ],
                    y: { value: 0, scale: 'yMarkScale', offset: { value: -4 } },
                    fontSize: { signal: '14 * chartScale' },
                    text: {
                        signal: "pitcherThrows == null ?  stlcTranslate('chart:firstBase_label', { defaultText: '1B' }) : stlcTranslate('chart:gloveside_label')",
                    },
                    align: [{ test: 'reverseArmGloveLabel', value: 'left' }, { value: 'right' }],
                },
            },
        },
        {
            type: 'rule',
            encode: {
                enter: {
                    x: { field: { group: 'x' } },
                    x2: { field: { group: 'width' } },
                    stroke: { value: '#CCCCCC' },
                    strokeWidth: { value: 1 },
                },
                update: {
                    y: { value: 0, scale: 'yMarkScale' },
                },
            },
        },
        {
            type: 'rule',
            encode: {
                enter: {
                    y: { value: 0 },
                    y2: { field: { group: 'height' } },
                    stroke: { value: '#CCCCCC' },
                    strokeWidth: { value: 1 },
                },
                update: {
                    x: { value: 0, scale: 'xMarkScale' },
                },
            },
        },
        {
            type: 'rule',
            interactive: false,
            encode: {
                enter: {
                    x: { field: { group: 'x' } },
                    x2: { field: { group: 'width' } },
                    stroke: { value: '#fff' },
                    strokeOpacity: { value: 0.5 },
                    strokeWidth: { value: 1 },
                },
                update: {
                    y: { value: 0, scale: 'yMarkScale', offset: 1 },
                },
            },
        },
        {
            type: 'rule',
            interactive: false,
            encode: {
                enter: {
                    y: { value: 0 },
                    y2: { field: { group: 'height' } },
                    stroke: { value: '#fff' },
                    strokeOpacity: { value: 0.5 },
                    strokeWidth: { value: 1 },
                },
                update: {
                    x: { value: 0, scale: 'xMarkScale', offset: 1 },
                },
            },
        },
        {
            type: 'group',
            from: {
                facet: {
                    name: 'ellipseData',
                    data: 'lgData',
                    field: 'ellipseData',
                },
            },
            data: [
                {
                    name: 'ellipse',
                    source: 'ellipseData',
                    transform: [
                        {
                            type: 'filter',
                            expr: "showLeague && datum.coordinateType == 'ellipse'",
                        },
                    ],
                },
            ],
            marks: [
                {
                    type: 'line',
                    from: { data: 'ellipse' },
                    encode: {
                        enter: {
                            fillOpacity: { value: 0.25 },
                            strokeOpacity: { value: 0.1 },
                            strokeWidth: { value: 0 },
                        },
                        update: {
                            x: { scale: 'xMarkScale', field: 'x' },
                            y: { scale: 'yMarkScale', field: 'y' },
                            fill: { scale: 'pitchColor', field: 'pitchType' },
                            // stroke: { scale: 'pitchColor', field: 'pitchType' },
                        },
                    },
                },
            ],
        },
        {
            name: 'hiddenMarks',
            type: 'symbol',
            from: { data: 'hiddenData' },
            interactive: false,
            encode: {
                enter: {
                    shape: { value: 'circle' },
                    fill: { value: '#cccccc' },
                    fillOpacity: { value: 0.2 },
                },
                update: {
                    x: { scale: 'xMarkScale', field: 'horizontalBreak' },
                    y: { scale: 'yMarkScale', field: 'inducedVerticalBreak' },
                    size: { signal: 'pitchSize' },
                },
            },
        },
        {
            type: 'group',
            from: { data: 'visibleData' },
            clip: true,
            encode: {
                update: {
                    zindex: {
                        signal: 'currentId && currentId == datum.id ? 2 : hoveredId && hoveredId == datum.id ? 1 : 0',
                    },
                },
            },
            signals: [
                {
                    name: 'isBrushedOrSelected',
                    update: '(brushedIds && indexof(brushedIds, parent.id) !== -1) || (selectedIds && indexof(selectedIds, parent.id) !== -1)',
                },
                { name: 'isHidden', update: '(brushedIds || selectedIds) && !isBrushedOrSelected' },
                {
                    name: 'isSelectable',
                    update: 'selectable && !isHidden && isArray(parent.videos) && length(parent.videos) > 0',
                },
            ],
            marks: [
                {
                    type: 'symbol',
                    interactive: false,
                    encode: {
                        update: {
                            x: { scale: 'x', field: { parent: 'horizontalBreak' } },
                            y: { scale: 'y', field: { parent: 'inducedVerticalBreak' } },
                            size: {
                                signal: 'pow((sqrt(pitchSize / PI) + 3), 2) * PI',
                            },
                            stroke: { scale: 'gColor', field: { parent: 'pitchType' } },
                            strokeWidth: {
                                signal: 'min(max(1, pow((sqrt(pitchSize / PI) + 3), 2) * PI / 96), 2)',
                            },
                            strokeOpacity: { value: 1 },
                            fill: { value: 'white' },
                            opacity: {
                                signal: '(currentId && parent.id == currentId) || (hoveredId && hoveredId == parent.id) ? 1 : 0',
                            },
                        },
                    },
                },
                {
                    name: 'visibleMarks',
                    type: 'symbol',
                    interactive: { signal: 'isInteractive && !isHidden' },
                    encode: {
                        update: {
                            x: { scale: 'xMarkScale', field: { parent: 'horizontalBreak' } },
                            y: { scale: 'yMarkScale', field: { parent: 'inducedVerticalBreak' } },
                            size: { signal: 'pitchSize' },
                            fill: { scale: 'gColor', signal: "isHidden ? 'Unknown' : parent.pitchType" },
                            fillOpacity: { signal: 'isHidden ? 0.2 : (hoveredId && hoveredId == parent.id) ? 1 : 0.5' },
                            stroke: { scale: 'gColor', signal: 'parent[gScale.field]' },
                            strokeOpacity: { signal: 'isBrushedOrSelected ? 1 : 0.75' },
                            strokeWidth: { signal: 'isBrushedOrSelected ? 1 : 0' },
                            tooltip: { signal: 'isInteractive && !isHidden ? parent : null' },
                            cursor: {
                                signal: "isSelectable ? 'pointer' : 'default'",
                            },
                        },
                    },
                },
            ],
        },
        {
            name: 'brush',
            type: 'rect',
            interactive: false,
            encode: {
                enter: {
                    fill: { value: '#0080FF' },
                    fillOpacity: { value: 0.1 },
                    strokeWidth: { value: 1 },
                    stroke: { value: '#0080FF' },
                    strokeOpacity: { value: 0.3 },
                },
                update: {
                    x: { signal: 'brushStart ? brushStart.x : null' },
                    x2: { signal: 'brushEnd.x' },
                    y: { signal: 'brushStart ? brushStart.y : null' },
                    y2: { signal: 'brushEnd.y' },
                    fillOpacity: { signal: 'brushed ? 0.1 : 0' },
                    strokeOpacity: { signal: 'brushed ? 0.3 : 0' },
                },
            },
        },
    ],
};
