import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { includes, isEmpty, map, range, transform } from '@stlc/lodash';
import { StringSelectOptionFragment } from '@stlc/player-charts/data-access';
import { UiChipSelectComponent } from '@stlc/ui';

@Component({
    selector: 'player-charts-ui-games-count-filter',
    templateUrl: './games-count-filter.component.html',
    styleUrls: ['./games-count-filter.component.scss'],
    standalone: true,
    imports: [UiChipSelectComponent],
})
export class PlayerChartsUiGamesCountFilterComponent implements OnChanges {
    @Input()
    selected: string[] | undefined = [];

    @Output()
    valueChange = new EventEmitter<string[] | undefined>();

    @Input()
    disabled: boolean;

    options: StringSelectOptionFragment[] = counts;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selected']) {
            // forEach(this.options, (option) => {
            //     option.selected = isNil(this.selected) ? false : includes(this.selected, option.value);
            // });
            this.options = map(counts, (count) => ({
                ...count,
                selected: includes(this.selected, count.value),
            }));
        }
    }

    emitValueChange(selected: StringSelectOptionFragment[]): void {
        if (isEmpty(selected)) {
            this.valueChange.emit(undefined);
        }

        this.valueChange.emit(map(selected, 'value') as string[]);
    }
}

const counts: StringSelectOptionFragment[] = transform(
    range(4),
    (results: StringSelectOptionFragment[], balls) => {
        range(3).forEach((strikes) => {
            results.push({
                text: `${balls}-${strikes}`,
                value: `${balls}-${strikes}`,
            });
        });
    },
    []
);
