<ng-container *ngIf="pitchesSignal().length > 0">
    <div *ngIf="header" class="text-gray-400 text-center mb-2">{{ header }}</div>
    <div class="flex justify-between divide-x divide-gray-100">
        <div *ngIf="!batterBats || batterBats === 'R'" class="flex flex-col items-center snap-center">
            <span class="text-gray-400">vs RHB</span>
            <ui-vega-chart
                [spec]="spec"
                [signals]="signals"
                [data]="rhbPitches()"
                [tooltipTemplate]="tooltipTemplate"
                [signalListeners]="signalListeners"
                [enableBrushing]="false"
            ></ui-vega-chart>
            <ng-container *ngTemplateOutlet="rhbCaption"></ng-container>
        </div>
        <div *ngIf="!batterBats || batterBats === 'L'" class="flex flex-col items-center snap-center">
            <span class="text-gray-400">vs LHB</span>
            <ui-vega-chart
                [spec]="spec"
                [signals]="signals"
                [data]="lhbPitches()"
                [tooltipTemplate]="tooltipTemplate"
                [signalListeners]="signalListeners"
                [enableBrushing]="false"
            ></ui-vega-chart>
            <ng-container *ngTemplateOutlet="lhbCaption"></ng-container>
        </div>
    </div>
</ng-container>

<ng-template #tooltipTemplate let-item="item">
    <player-charts-ui-pitching-tooltip [pitch]="item" showBatter="true"></player-charts-ui-pitching-tooltip>
</ng-template>
