import type { Spec } from 'vega';

import { isArray } from '@stlc/lodash';
import { getDefaultSpec } from '@stlc/vega/utils';

// Size of image
export const defaultHeight = 960;
export const defaultWidth = 750;
// 0, 0 mark is centered and 862 pixels from top of image
const zeroX = defaultWidth / 2;
const zeroY = 862;
// 0, 2 mark is 34 pixels from edge of image
const pixelsInOneFoot = (zeroX - 34) / 2;

export class StlcStrikeZoneImageOverlaySpec {
    private options = {
        fillOpacityModifier: 1.25,
        interactive: false,
        maxX: zeroX / pixelsInOneFoot,
        maxY: zeroY / pixelsInOneFoot,
        minX: -zeroX / pixelsInOneFoot,
        minY: -(defaultHeight - zeroY) / pixelsInOneFoot,
        perspective: 'pitcher',
        width: defaultWidth,
        pitchSource: 'gashouse',
    };

    constructor(private readonly pitchColorField: 'pitchType' | 'pitchClass') {}

    private setSpecScales(spec: Spec): void {
        if (!isArray(spec.scales)) {
            spec.scales = [];
        }

        spec.scales.push(
            {
                name: 'x',
                type: 'linear',
                domain: [this.options.minX, this.options.maxX],
                range: 'width',
                clamp: true,
                reverse: false,
            },
            {
                name: 'y',
                type: 'linear',
                domain: [this.options.minY, this.options.maxY],
                range: 'height',
                zero: false,
                clamp: true,
            },
            {
                name: 'pitchClassColorScale',
                type: 'ordinal',
                domain: ['Fastball', 'Breaking', 'Offspeed', 'Other'],
                range: ['#FFF', '#000', '#008b8b', '#737373'],
            },
            {
                name: 'pitchClassStrokeColorScale',
                type: 'ordinal',
                domain: ['Fastball', 'Breaking', 'Offspeed', 'Other'],
                range: ['#000', '#000', '#008b8b', '#737373'],
            }
        );
    }

    private setSpecSignals(spec: Spec): void {
        if (!isArray(spec.signals)) {
            spec.signals = [];
        }

        spec.signals.push(
            {
                name: 'defaultWidth',
                value: defaultWidth,
            },
            {
                name: 'defaultHeight',
                value: defaultHeight,
            },
            {
                name: 'height',
                update: 'width / defaultWidth * defaultHeight',
            },
            {
                name: 'image',
            },
            {
                name: 'selectedPitchTypes',
                value: [],
            },
            {
                name: 'showPitches',
            }
        );
    }

    private setSpecMarks(spec: Spec): void {
        if (!isArray(spec.marks)) {
            spec.marks = [];
        }

        const fill =
            this.pitchColorField === 'pitchClass'
                ? { scale: 'pitchClassColorScale', field: 'pitchClass' }
                : { value: '#0f0' };
        const stroke =
            this.pitchColorField === 'pitchClass'
                ? { scale: 'pitchClassStrokeColorScale', field: 'pitchClass' }
                : { value: '#000' };
        const { minX, maxX, minY, maxY } = this.options;

        spec.marks.push(
            {
                type: 'image',
                encode: {
                    update: {
                        image: { signal: 'image' },
                        width: { signal: 'width' },
                        height: { signal: 'height' },
                    },
                },
            },
            {
                type: 'symbol',
                from: { data: 'data' },
                encode: {
                    enter: {
                        fillOpacity: { value: 0.95 },
                        shape: {
                            value: 'circle',
                        },
                    },
                    update: {
                        x: {
                            scale: 'x',
                            signal: `clamp(datum.plateSide, ${minX * 0.95}, ${maxX * 0.95})`, // prevent extreme marks from expanding size of chart
                        },
                        y: {
                            scale: 'y',
                            signal: `clamp(datum.plateHeight, ${minY * 0.85}, ${maxY * 0.9})`, // prevent extreme marks from expanding size of chart
                        },
                        fill,
                        stroke,
                        strokeWidth: { value: this.pitchColorField === 'pitchClass' ? 0.5 : 1 },
                        strokeOpacity: { value: this.pitchColorField === 'pitchClass' ? 0.5 : 1 },
                        size: { signal: '150 * width / defaultWidth' },
                        opacity: [{ test: 'showPitches', value: 1 }, { value: 0 }],
                    },
                    hover: {
                        size: { signal: '250 * width / defaultWidth' },
                        tooltip: { signal: 'datum' },
                    },
                },
            }
        );
    }

    private setSpecData(spec): void {
        if (!isArray(spec.data)) {
            spec.data = [];
        }

        spec.data.push({
            name: 'data',
            values: [],
            transform: [
                {
                    type: 'filter',
                    expr: 'isValid(datum.plateSide) && isValid(datum.plateHeight)',
                },
                {
                    type: 'filter',
                    expr: 'length(selectedPitchTypes) == 0 || indexof(selectedPitchTypes, datum.pitchType) >= 0',
                },
            ],
        });
    }

    getSpec(): Spec {
        const { minX, maxX, minY, maxY } = this.options;
        const spec = getDefaultSpec({
            width: 100,
            minX,
            maxX,
            minY,
            maxY,
            defaultWidth: 'fit-x',
            backgroundColor: 'transparent',
        });

        this.setSpecScales(spec);
        this.setSpecSignals(spec);
        this.setSpecMarks(spec);
        this.setSpecData(spec);

        return spec;
    }
}
