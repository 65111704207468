import { StlcSelectOption, StlcSelectOptionGroup } from '@stlc/shared';

import { PlayerGameLogSummaryFragment } from './generated';

export type PlayerGameLogSummarySelectOption = StlcSelectOption & { summary: PlayerGameLogSummaryFragment };
export type PlayerGameLogSummarySelectOptionGroup = StlcSelectOptionGroup<string, PlayerGameLogSummarySelectOption>;

export enum PlayerChartsGamesQueryParams {
    Stat = 'stat',
    Game = 'game',
    View = 'view',
    PitchType = 'pitchType',
    BatterBats = 'batterBats',
    Count = 'count',
    PitchResult = 'pitchResult',
    BasesOccupied = 'basesOccupied',
    PitcherThrows = 'pitcherThrows',
    Other = 'other',
}
