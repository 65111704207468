<ng-container *ngIf="!!pitch">
    <div class="flex flex-col text-xs max-w-lg whitespace-nowrap">
        <div class="flex flex-col">
            <div class="font-bold text-center">
                PA #{{ pitch.atBatNumber }}, {{ 'common:pitch' | i18next }} #{{ pitch.atbatPitchNumber }}
            </div>
            <div class="font-bold text-center">
                {{ pitch.isTopInning ? 'Top' : 'Bottom' }} {{ pitch.inning | stlcNumberToOrdinal }},
                {{ pitch.preBalls }} - {{ pitch.preStrikes }} {{ 'game:out' | i18next : { count: pitch.preOuts } }}
            </div>
            <div class="font-bold text-center" *ngIf="pitch.pitcher">vs {{ pitch.pitcher | stlcPlayerName }}</div>
        </div>
    </div>

    <div class="flex flex-col text-xs max-w-lg whitespace-nowrap mt-1">
        <div class="text-center" *ngIf="pitch.pitchType">
            {{ pitch.pitchType | stlcI18nPitchType : 'legacy' }}
        </div>
        <div class="flex justify-between" *ngIf="pitch.releaseSpeed || pitch.releaseSpinRate">
            <div>
                {{ pitch.releaseSpeed | stlcNumberToFixed : { digits: 0, defaultText: '-', appendText: ' MPH' } }}
            </div>
            <div>
                {{
                    pitch.releaseSpinRate
                        | stlcNumberToFixed
                            : {
                                  digits: 0,
                                  roundToNearest: 5,
                                  appendText: ' RPM'
                              }
                }}
            </div>
        </div>
        <div class="flex justify-between" *ngIf="pitch.inducedVerticalBreak || pitch.horizontalBreak">
            <div>{{ pitch.inducedVerticalBreak | stlcFeetAndInches : { input: 'inches', output: 'inches' } }} IVB</div>
            <div>{{ pitch.horizontalBreak | stlcFeetAndInches : { input: 'inches', output: 'inches' } }} HB</div>
        </div>
    </div>
    <div class="text-xs text-center mt-1">{{ pitch | stlcI18nGameEvent }}</div>
    <div
        class="mt-1"
        *ngIf="
            pitch.launchInitialSpeed ||
            pitch.hitLaunchDistance ||
            pitch.launchVerticalAngle ||
            pitch.sprayAngle ||
            pitch.hitLaunchBearing
        "
    >
        <mat-divider></mat-divider>
        <div class="flex flex-col text-xs max-w-lg whitespace-nowrap">
            <div class="text-center mt-1">Launch</div>
            <div
                class="flex justify-between"
                *ngIf="pitch.hitLaunchDistance || pitch.sprayAngle || pitch.hitLaunchBearing"
            >
                <div [class.invisible]="!pitch.hitLaunchDistance">
                    {{ pitch.hitLaunchDistance | stlcNumberToFixed : { digits: 0, appendText: "'" } }} distance
                </div>
                <div [class.invisible]="!pitch.hitLaunchBearing && !pitch.sprayAngle">
                    {{
                        pitch.sprayAngle || pitch.hitLaunchBearing | stlcNumberToFixed : { digits: 0, appendText: '˚' }
                    }}
                    SA
                </div>
            </div>
            <div
                class="flex justify-between"
                *ngIf="
                    pitch.launchInitialSpeed ||
                    pitch.hitLaunchInitialSpeed ||
                    pitch.launchVerticalAngle ||
                    pitch.hitLaunchVerticalAngle
                "
            >
                <div [class.invisible]="!pitch.launchInitialSpeed && !pitch.hitLaunchInitialSpeed">
                    {{
                        pitch.launchInitialSpeed || pitch.hitLaunchInitialSpeed
                            | stlcNumberToFixed : { digits: 0, appendText: ' MPH' }
                    }}
                </div>
                <div [class.invisible]="!pitch.launchVerticalAngle && !pitch.hitLaunchVerticalAngle">
                    {{
                        pitch.launchVerticalAngle || pitch.hitLaunchVerticalAngle
                            | stlcNumberToFixed : { digits: 0, appendText: '˚' }
                    }}
                    LA
                </div>
            </div>
        </div>
    </div>
    <div
        class="mt-1"
        *ngIf="
            pitch.batterBatSpeed || pitch.batterAttackAngle || pitch.batterCommitTime || pitch.batterPlanarEfficiency
        "
    >
        <mat-divider></mat-divider>
        <div class="flex flex-col text-xs max-w-lg whitespace-nowrap pt-2">
            <div
                class="text-center"
                [innerHTML]="'stat:batTracking_header' | i18next : { defaultValue: 'Bat Tracking' }"
            ></div>
            <div class="flex justify-between" *ngIf="pitch.batterBatSpeed || pitch.batterCommitTime">
                <div>
                    {{
                        pitch.batterBatSpeed
                            | stlcNumberToFixed
                                : {
                                      digits: 1,
                                      defaultText: '-'
                                  }
                    }}
                    MPH
                </div>
                <div>
                    {{
                        pitch.batterCommitTime
                            | stlcNumberToFixed
                                : {
                                      digits: 0,
                                      appendText: 'ms',
                                      operand: 1000,
                                      method: 'multiply',
                                      defaultText: '-'
                                  }
                    }}
                    CT
                </div>
            </div>
            <div class="flex justify-between" *ngIf="pitch.batterPlanarEfficiency || pitch.batterAttackAngle">
                <div>
                    {{
                        pitch.batterPlanarEfficiency
                            | stlcNumberToFixed
                                : {
                                      digits: 0,
                                      appendText: '%',
                                      operand: 100,
                                      method: 'multiply',
                                      defaultText: '-'
                                  }
                    }}
                    OP
                </div>
                <div>
                    {{
                        pitch.batterAttackAngle
                            | stlcNumberToFixed
                                : {
                                      digits: 1,
                                      appendText: '˚',
                                      defaultText: '-'
                                  }
                    }}
                    AA
                </div>
            </div>
        </div>
    </div>
</ng-container>
