import type { Spec } from 'vega';

import { map } from '@stlc/lodash';
import { pitchTypes } from '@stlc/lookup/legacy';

export const gamePitches: Spec = {
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    width: 450,
    height: 300,
    autosize: { type: 'fit', contains: 'padding' },
    usermeta: { resize: true },
    background: 'transparent',
    config: {
        title: {
            font: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            fontSize: 16,
            fontWeight: 'normal',
            color: '#333333',
        },
        axis: {
            domainColor: '#aaaaaa',
            domainOpacity: 1,
            gridColor: '#dddddd',
            gridOpacity: 1,
            labelColor: '#333333',
            labelFont: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            labelFontSize: 11,
            labelFontWeight: 'normal',
            tickColor: '#999999',
            titleFont: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            titleFontSize: 16,
            titleFontWeight: 'normal',
            titleColor: '#333333',
            titlePadding: 16,
        },
        text: {
            font: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
        },
        events: { defaults: { allow: ['wheel'] } },
    },
    signals: [
        {
            name: 'xAxis',
            value: {
                field: 'pitchNumber',
                plateAppearanceIndexField: 'inningPa',
                title: 'Pitch Number',
                titleKey: 'chart:pitchNumber_label',
                heading: 'Pitch Number',
                label: 'Pitch',
                zero: true,
                nice: true,
                round: true,
                tickCount: 5,
                domainMin: 1,
                domainMax: 20,
            },
        },
        { name: 'yAxis', value: {} },
        { name: 'showYAxisTitle', value: false },
        { name: 'showInningText', value: false },
        { name: 'insufficientData', update: "length(data('data')) === 0" },
        {
            name: 'gScale',
            value: {
                field: 'pitchType',
                label: {
                    name: 'pitchTypeAbbrev',
                    type: 'ordinal',
                    domain: map(pitchTypes, 'name'),
                    range: map(pitchTypes, (datum) => datum.abbrev || datum.value),
                },
                color: {
                    name: 'pitchTypeColor',
                    type: 'ordinal',
                    domain: map(pitchTypes, 'name'),
                    range: map(pitchTypes, 'color'),
                },
            },
        },
        { name: 'pitchTypes', value: [] },
        { name: 'hasData', update: "length(data('data')) > 0" },
        { name: 'brushable', value: false },
        { name: 'selectable', value: true },
        { name: 'isInteractive', update: '!brushable && selectable' },
        { name: 'hoveredId', value: null },
        {
            name: 'hovered',
            value: null,
            on: [
                { events: '@visibleMarks:mouseover', update: '!brushed && group().datum ? group().datum.id : null' },
                { events: '@visibleMarks:mouseout', update: 'null' },
            ],
        },
        { name: 'filteredIds', value: null },
        { name: 'selectedIds', value: null },
        {
            name: 'selectedId',
            value: null,
            on: [
                {
                    events: [
                        {
                            merge: [
                                {
                                    type: 'mousedown',
                                    markname: 'visibleMarks',
                                    consume: true,
                                },
                                {
                                    type: 'touchstart',
                                    markname: 'visibleMarks',
                                    consume: true,
                                },
                            ],
                        },
                    ],
                    update: 'group().datum ? group().datum.id : null',
                    force: true,
                },
            ],
        },
        {
            name: 'brushStart',
            value: null,
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                        },
                        {
                            type: 'touchstart',
                            consume: true,
                        },
                    ],
                    update: 'brushable ? { x: clamp(x(), 0, width), y: clamp(y(), 0, height) } : null',
                },
            ],
        },
        {
            name: 'brushEnd',
            value: {},
            on: [
                {
                    events: 'mousedown, [mousedown, window:mouseup] > window:mousemove, touchstart, [touchstart, window:touchend] > window:touchmove',
                    update: 'brushable ? { x: clamp(x(), 0, width), y: clamp(y(), 0, height) } : {}',
                },
            ],
        },
        {
            name: 'brushed',
            value: false,
            on: [
                {
                    events: [{ type: 'mousedown' }, { type: 'touchstart' }],
                    update: '!selectable && brushable ? true : false',
                },
                { events: 'window:mouseup, window:touchend', update: 'false' },
            ],
        },
        {
            name: 'brushX',
            update: `brushStart ? extent(invert('x', [brushStart.x, brushEnd.x])) : []`,
        },
        {
            name: 'brushY',
            update: `brushStart ? extent(invert('y', [brushStart.y, brushEnd.y])) : []`,
        },
        {
            name: 'brushOperation',
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                        },
                        {
                            type: 'touchstart',
                            consume: true,
                        },
                    ],
                    update: "event.shiftKey && !event.altKey ? 'add' : event.altKey && !event.shiftKey ? 'remove' : null",
                },
            ],
        },
        { name: 'brushedIds', value: null, react: true },
        { name: 'currentId', value: null },
        { name: 'hasSelected', update: '(selectedIds && length(selectedIds)) || (brushedIds && length(brushedIds))' },
    ],
    data: [
        {
            name: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: "isDefined(datum[xAxis.field]) && isDefined(datum[yAxis.field]) && datum.pitchType && datum.pitchType !== 'unk' && datum.pitchType !== 'IN' && (!isArray(pitchTypes) || indexof(pitchTypes, datum.pitchType) >= 0)",
                },
            ],
        },
        {
            name: 'visibleData',
            source: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: '!filteredIds || (filteredIds && length(filteredIds) > 0 && indexof(filteredIds, datum.id) >= 0)',
                },
            ],
        },
        {
            name: 'hiddenData',
            source: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: 'filteredIds && (length(filteredIds) == 0 || indexof(filteredIds, datum.id) == -1)',
                },
            ],
        },
        {
            name: 'contextData',
            values: [],
            transform: [
                {
                    type: 'filter',
                    expr: "isDefined(datum[xAxis.field]) && isDefined(datum[yAxis.field]) && datum.pitchType && datum.pitchType !== 'unk' && datum.pitchType !== 'IN' && (!isArray(pitchTypes) || indexof(pitchTypes, datum.pitchType) >= 0)",
                },
            ],
        },
        {
            name: 'context2Data',
            values: [],
            transform: [
                {
                    type: 'filter',
                    expr: "isDefined(datum[xAxis.field]) && isDefined(datum[yAxis.field]) && datum.pitchType && datum.pitchType !== 'unk' && datum.pitchType !== 'IN' && (!isArray(pitchTypes) || indexof(pitchTypes, datum.pitchType) >= 0)",
                },
            ],
        },
        {
            name: 'contextTrendData',
            source: 'contextData',
            transform: [
                {
                    type: 'loess',
                    groupby: [{ signal: 'gScale.field' }],
                    bandwidth: 1,
                    x: { signal: 'xAxis.field' },
                    y: { signal: 'yAxis.field' },
                },
                { type: 'formula', expr: 'datum[xAxis.field]', as: 'x' },
                { type: 'formula', expr: 'datum[yAxis.field]', as: 'y' },
            ],
        },
        {
            name: 'context2TrendData',
            source: 'context2Data',
            transform: [
                {
                    type: 'loess',
                    groupby: [{ signal: 'gScale.field' }],
                    bandwidth: 1,
                    x: { signal: 'xAxis.field' },
                    y: { signal: 'yAxis.field' },
                },
                { type: 'formula', expr: 'datum[xAxis.field]', as: 'x' },
                { type: 'formula', expr: 'datum[yAxis.field]', as: 'y' },
            ],
        },
        {
            name: 'inningData',
            source: 'data',
            transform: [
                {
                    type: 'aggregate',
                    fields: [{ signal: 'xAxis.field' }, { signal: 'xAxis.field' }],
                    as: ['pitchNumberMin', 'pitchNumberMax'],
                    ops: ['min', 'max'],
                    groupby: ['idlGameId', 'inning'],
                },
                {
                    type: 'formula',
                    expr: 'datum.pitchNumberMin - 0.5',
                    as: 'pitchNumberMin',
                },
                {
                    type: 'formula',
                    expr: 'datum.pitchNumberMax + 0.5',
                    as: 'pitchNumberMax',
                },
                {
                    type: 'formula',
                    expr: '(datum.pitchNumberMin + datum.pitchNumberMax) / 2',
                    as: 'pitchNumberCenter',
                },
            ],
        },
        {
            name: 'gameData',
            source: 'data',
            transform: [
                {
                    type: 'aggregate',
                    fields: [{ signal: 'xAxis.field' }, { signal: 'xAxis.field' }],
                    as: ['pitchNumberMin', 'pitchNumberMax'],
                    ops: ['min', 'max'],
                    groupby: ['gameDate', 'idlGameId', 'gameNumber'],
                },
                {
                    type: 'formula',
                    expr: 'datum.pitchNumberMin - 0.5',
                    as: 'pitchNumberMin',
                },
                {
                    type: 'formula',
                    expr: 'datum.pitchNumberMax + 0.5',
                    as: 'pitchNumberMax',
                },
                {
                    type: 'formula',
                    expr: '(datum.pitchNumberMin + datum.pitchNumberMax) / 2',
                    as: 'pitchNumberCenter',
                },
            ],
        },
        {
            name: 'plateAppearanceData',
            source: 'data',
            transform: [
                {
                    type: 'aggregate',
                    fields: [{ signal: 'xAxis.field' }],
                    as: ['pitchNumberMin'],
                    ops: ['min'],
                    groupby: [
                        'inning',
                        {
                            signal: "isDefined(xAxis.plateAppearanceIndexField) ? xAxis.plateAppearanceIndexField : 'atBatIndex'",
                        },
                    ],
                },
                {
                    type: 'formula',
                    expr: 'datum.pitchNumberMin - 0.5',
                    as: 'pitchNumberMin',
                },
            ],
        },
        {
            name: 'brushedData',
            source: 'visibleData',
            transform: [
                {
                    type: 'filter',
                    expr: ['inrange(datum[xAxis.field], brushX)', 'inrange(datum[yAxis.field], brushY)'].join(' && '),
                },
            ],
        },
    ],
    scales: [
        {
            name: 'x',
            type: 'linear',
            round: { signal: 'xAxis.round' },
            // nice: { signal: 'xAxis.nice' },
            zero: { signal: 'xAxis.zero' },
            clamp: { signal: 'xAxis.clamp' },
            domain: {
                fields: [
                    { data: 'data', field: { signal: 'xAxis.field' } },
                    { data: 'contextData', field: { signal: 'xAxis.field' } },
                    { data: 'context2Data', field: { signal: 'xAxis.field' } },
                    { signal: 'xAxis.domainMin' },
                    { signal: 'xAxis.domainMax' },
                ],
            },
            range: 'width',
        },
        {
            name: 'y',
            type: 'linear',
            round: { signal: 'yAxis.round' },
            nice: { signal: 'yAxis.nice' },
            zero: { signal: 'yAxis.zero' },
            clamp: { signal: 'yAxis.clamp' },
            domain: {
                fields: [
                    { data: 'data', field: { signal: 'yAxis.field' } },
                    { data: 'contextData', field: { signal: 'yAxis.field' } },
                    { data: 'context2Data', field: { signal: 'yAxis.field' } },
                    { signal: 'yAxis.domainMin' },
                    { signal: 'yAxis.domainMax' },
                ],
            },
            range: 'height',
        },
        {
            name: 'gColor',
            type: 'ordinal',
            domain: { signal: 'gScale.color.domain' },
            range: { signal: 'gScale.color.range' },
        },
        {
            name: 'gLabel',
            type: 'ordinal',
            domain: { signal: 'gScale.label.domain' },
            range: { signal: 'gScale.label.range' },
        },
    ],
    axes: [
        {
            scale: 'x',
            grid: false,
            domain: false,
            orient: 'bottom',
            tickCount: { signal: 'xAxis.tickCount' },
            title: {
                signal: "stlcTranslate('chart:' + xAxis.field + '_label', { defaultValue: xAxis.title })",
            },
            encode: {
                labels: {
                    update: {
                        text: {
                            signal: '(isObject(xAxis.unit) || xAxis.unit === "feet" ? stlcFeetAndInches(datum.value, xAxis.unit) : xAxis.unit === "inches" ? datum.value + "\\"" : xAxis.unit === "degrees" ? datum.value + "°" : datum.value)',
                        },
                        fillOpacity: { signal: 'hasData ? 1 : 0' },
                    },
                },
                title: { update: { fillOpacity: { signal: 'hasData ? 1 : 0' } } },
                ticks: { update: { strokeOpacity: { signal: 'hasData ? 1 : 0' } } },
            },
        },
        {
            scale: 'y',
            grid: true,
            domain: false,
            orient: 'left',
            title: {
                signal: "showYAxisTitle ? stlcTranslate('stat:' + yAxis.field + '_label', { defaultValue: yAxis.title }) : null",
            },
            titlePadding: { signal: 'showYAxisTitle ? 16 : 0' },
            tickMinStep: { signal: 'yAxis.tickMinStep' },
            tickCount: { signal: 'yAxis.tickCount' },
            format: { signal: 'yAxis.format' },
            encode: {
                labels: {
                    update: {
                        text: {
                            signal: '(isObject(yAxis.unit) || yAxis.unit === "feet" ? stlcFeetAndInches(datum.value, yAxis.unit) : yAxis.unit === "inches" ? datum.value + "\\""  : yAxis.unit === "degrees" ? datum.value + "°" : datum.value)',
                        },
                        fillOpacity: { signal: 'hasData ? 1 : 0' },
                    },
                },
                title: { update: { fillOpacity: { signal: 'hasData ? 1 : 0' } } },
                ticks: { update: { strokeOpacity: { signal: 'hasData ? 1 : 0' } } },
            },
        },
    ],
    marks: [
        {
            type: 'group',
            from: {
                facet: {
                    data: 'contextTrendData',
                    name: 'curve',
                    groupby: 'pitchType',
                },
            },
            marks: [
                {
                    type: 'line',
                    from: { data: 'curve' },
                    encode: {
                        enter: {
                            strokeOpacity: { value: 0.5 },
                            strokeWidth: { value: 2 },
                        },
                        update: {
                            stroke: {
                                scale: 'gColor',
                                field: { signal: 'gScale.field' },
                            },
                            x: { scale: 'x', signal: 'datum[xAxis.field]' },
                            y: { scale: 'y', signal: 'datum[yAxis.field]' },
                        },
                    },
                },
            ],
        },
        {
            type: 'group',
            from: {
                facet: {
                    data: 'context2TrendData',
                    name: 'curve',
                    groupby: 'pitchType',
                },
            },
            marks: [
                {
                    type: 'line',
                    from: { data: 'curve' },
                    encode: {
                        enter: {
                            strokeDash: { value: [6, 3] },
                            strokeOpacity: { value: 0.5 },
                            strokeWidth: { value: 2 },
                        },
                        update: {
                            stroke: {
                                scale: 'gColor',
                                field: { signal: 'gScale.field' },
                            },
                            x: { scale: 'x', signal: 'datum[xAxis.field]' },
                            y: { scale: 'y', signal: 'datum[yAxis.field]' },
                        },
                    },
                },
            ],
        },
        {
            type: 'rect',
            interactive: false,
            from: { data: 'inningData' },
            encode: {
                enter: {
                    fill: { value: '#000' },
                },
                update: {
                    x: { scale: 'x', field: 'pitchNumberMin' },
                    x2: { scale: 'x', field: 'pitchNumberMax' },
                    fillOpacity: { signal: "indexof(data('inningData'), datum) % 2 === 1 ? 0 : 0.04" },
                    height: { signal: 'height' },
                },
            },
        },
        {
            type: 'rule',
            interactive: false,
            from: { data: 'plateAppearanceData' },
            encode: {
                enter: {
                    x: { scale: 'x', field: 'pitchNumberMin' },
                    y: { value: 0 },
                    y2: { signal: 'height' },
                    stroke: { value: '#000' },
                    strokeDash: { value: [4, 4] },
                    strokeOpacity: {
                        signal: "indexof(data('plateAppearanceData'), datum) > 0 ? 0.2 : 0",
                    },
                },
            },
        },
        {
            type: 'text',
            interactive: false,
            from: { data: 'inningData' },
            encode: {
                enter: {
                    fill: { value: 'gray' },
                    fillOpacity: { value: 0.5 },
                    align: { value: 'center' },
                },
                update: {
                    x: { scale: 'x', field: 'pitchNumberCenter' },
                    y: { signal: 'height', offset: -10 },
                    text: { signal: 'stlcNumberToOrdinal(datum.inning)' },
                    opacity: { signal: 'showInningText ? 1 : 0' },
                },
            },
        },
        {
            type: 'text',
            interactive: false,
            from: { data: 'gameData' },
            encode: {
                enter: {
                    y: { value: -5 },
                    fill: { value: 'gray' },
                    align: { value: 'center' },
                },
                update: {
                    x: { scale: 'x', field: 'pitchNumberCenter' },
                    fillOpacity: [{ test: "length(data('gameData')) > 1", value: 0.5 }, { value: 0 }],
                    text: { signal: 'stlcDaysJsFormat(datum.gameDate, "MMM D")' },
                },
            },
        },
        {
            type: 'rule',
            interactive: false,
            from: { data: 'gameData' },
            encode: {
                enter: {
                    y: { value: -15 },
                    stroke: { value: '#DDD' },
                    strokeWidth: { value: 2 },
                },
                update: {
                    x: { scale: 'x', field: 'pitchNumberMin' },
                    y2: { signal: 'height' },
                    strokeOpacity: [{ test: "indexof(data('gameData'), datum) == 0", value: 0 }, { value: 1 }],
                },
            },
        },
        {
            name: 'hiddenMarks',
            type: 'symbol',
            from: { data: 'hiddenData' },
            interactive: false,
            encode: {
                enter: {
                    shape: { value: 'circle' },
                    size: { value: 128 },
                    fill: { value: '#cccccc' },
                    fillOpacity: { value: 0.2 },
                },
                update: {
                    x: { scale: 'x', field: { signal: 'xAxis.field' } },
                    y: { scale: 'y', field: { signal: 'yAxis.field' } },
                },
            },
        },
        {
            type: 'group',
            from: { data: 'visibleData' },
            encode: {
                update: {
                    zindex: {
                        signal: 'currentId && currentId == datum.id ? 2 : hoveredId && hoveredId == datum.id ? 1 : 0',
                    },
                },
            },
            signals: [
                {
                    name: 'isBrushedOrSelected',
                    update: '(brushedIds && indexof(brushedIds, parent.id) !== -1) || (selectedIds && indexof(selectedIds, parent.id) !== -1)',
                },
                { name: 'isHidden', update: '(brushedIds || selectedIds) && !isBrushedOrSelected' },
                {
                    name: 'isSelectable',
                    update: 'selectable && !isHidden && isArray(parent.videos) && length(parent.videos) > 0',
                },
            ],
            marks: [
                {
                    type: 'symbol',
                    interactive: false,
                    encode: {
                        update: {
                            x: { scale: 'x', signal: 'parent[xAxis.field]' },
                            y: { scale: 'y', signal: 'parent[yAxis.field]' },
                            size: {
                                signal: 'pow((sqrt(128 / PI) + 3), 2) * PI',
                            },
                            stroke: { scale: 'gColor', signal: 'parent[gScale.field]' },
                            strokeWidth: { signal: 'min(max(1, pow((sqrt(128 / PI) + 3), 2) * PI / 96), 2)' },
                            strokeOpacity: { value: 1 },
                            fill: { value: 'white' },
                            opacity: {
                                signal: '(currentId && parent.id == currentId) || (hoveredId && hoveredId == parent.id) ? 1 : 0',
                            },
                        },
                    },
                },
                {
                    name: 'visibleMarks',
                    type: 'symbol',
                    interactive: { signal: 'isInteractive && !isHidden' },
                    encode: {
                        update: {
                            x: { scale: 'x', signal: 'parent[xAxis.field]' },
                            y: { scale: 'y', signal: 'parent[yAxis.field]' },
                            size: { value: 128 },
                            fill: { scale: 'gColor', signal: "isHidden ? 'Unknown' : parent[gScale.field]" },
                            fillOpacity: { signal: 'isHidden ? 0.2 : (hoveredId && hoveredId == parent.id) ? 1 : 0.5' },
                            stroke: { scale: 'gColor', signal: 'parent[gScale.field]' },
                            strokeOpacity: { signal: 'isBrushedOrSelected ? 1 : 0.75' },
                            strokeWidth: { signal: 'isBrushedOrSelected ? 1 : 0' },
                            opacity: { signal: '!isNumber(parent[yAxis.field]) ? 0 : 1' },
                            tooltip: { signal: 'isInteractive && !isHidden ? parent : null' },
                            cursor: { signal: "isSelectable ? 'pointer' : 'default'" },
                        },
                    },
                },
                // Show vertical line that is the height of the chart when the yAxis value is undefined
                {
                    type: 'rule',
                    interactive: false,
                    encode: {
                        enter: {
                            strokeOpacity: { value: 0.4 },
                        },
                        update: {
                            x: {
                                scale: 'x',
                                signal: 'currentId && currentId == parent.id ? parent[xAxis.field] : 0',
                            },
                            y: { value: 0 },
                            y2: { signal: 'height' },
                            stroke: {
                                scale: 'gColor',
                                signal: 'currentId && currentId == parent.id ? parent[gScale.field] : 0',
                            },
                            opacity: {
                                signal: '!parent[yAxis.field] && currentId && currentId == parent.id ? 1 : 0',
                            },
                        },
                    },
                },
                {
                    type: 'rule',
                    interactive: false,
                    encode: {
                        enter: {
                            strokeOpacity: { value: 1 },
                        },
                        update: {
                            x: {
                                scale: 'x',
                                signal: 'currentId && currentId == parent.id ? parent[xAxis.field] : 0',
                            },
                            y: {
                                scale: 'y',
                                signal: 'currentId && currentId == parent.id ? parent[yAxis.field] : 0',
                                offset: { value: 14 },
                            },
                            y2: { signal: 'height' },
                            stroke: {
                                scale: 'gColor',
                                signal: 'currentId && currentId == parent.id ? parent[gScale.field] : 0',
                            },
                            opacity: { signal: 'currentId && currentId == parent.id ? 1 : 0' },
                        },
                    },
                },
                {
                    type: 'rule',
                    interactive: false,
                    encode: {
                        enter: {
                            strokeOpacity: { value: 1 },
                        },
                        update: {
                            x: {
                                scale: 'x',
                                signal: 'currentId && currentId == parent.id ? parent[xAxis.field] : 0',
                            },
                            y: { value: 0 },
                            y2: {
                                scale: 'y',
                                signal: 'currentId && currentId == parent.id && parent[yAxis.field] ? parent[yAxis.field] : 0',
                                offset: { value: -14 },
                            },
                            stroke: {
                                scale: 'gColor',
                                signal: 'currentId && currentId == parent.id ? parent[gScale.field] : 0',
                            },
                            opacity: { signal: 'currentId && currentId == parent.id ? 1 : 0' },
                        },
                    },
                },
            ],
        },
        {
            type: 'text',
            interactive: false,
            encode: {
                enter: {
                    align: { value: 'center' },
                    baseline: { value: 'middle' },
                    fontSize: { value: 16 },
                    fill: { value: '#666' },
                },
                update: {
                    x: { signal: 'width / 2' },
                    y: { signal: 'height / 2' },
                    opacity: { value: 1 },
                    text: {
                        signal: "!hasData ? (isArray(pitchTypes) && length(pitchTypes) === 0 ? 'No pitch selected' : 'No data found') : ''",
                    },
                },
            },
        },
        {
            name: 'brush',
            type: 'rect',
            interactive: false,
            encode: {
                enter: {
                    fill: { value: '#0080FF' },
                    fillOpacity: { value: 0.1 },
                    strokeWidth: { value: 1 },
                    stroke: { value: '#0080FF' },
                    strokeOpacity: { value: 0.3 },
                },
                update: {
                    x: { signal: 'brushStart ? brushStart.x : null' },
                    x2: { signal: 'brushEnd.x' },
                    y: { signal: 'brushStart ? brushStart.y : null' },
                    y2: { signal: 'brushEnd.y' },
                    fillOpacity: { signal: 'brushed ? 0.1 : 0' },
                    strokeOpacity: { signal: 'brushed ? 0.3 : 0' },
                },
            },
        },
    ],
};
