import type { Spec } from 'vega';

import { map } from '@stlc/lodash';
import { pitchTypes } from '@stlc/lookup/statcast';

export const pitchRelease: Spec = {
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    width: 180,
    height: 261.8181818181818,
    autosize: { type: 'pad', contains: 'padding' },
    usermeta: { resize: false },
    background: 'transparent',
    config: {
        title: {
            font: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            fontSize: 16,
            fontWeight: 'normal',
            color: '#333333',
        },
        axis: {
            domainOpacity: 1,
            gridOpacity: 1,
            tickOpacity: 0,
            domainColor: '#aaaaaa',
            gridColor: '#dddddd',
            labelColor: '#333333',
            labelFont: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            labelFontSize: 11,
            labelFontWeight: 'normal',
            tickColor: '#999999',
            titleFont: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            titleFontSize: 16,
            titleFontWeight: 'normal',
            titleColor: '#333333',
            titlePadding: 16,
        },
        text: {
            font: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
        },
        events: { defaults: { allow: ['wheel'] } },
    },
    signals: [
        { name: 'perspective', value: 'pitcher' },
        { name: 'pitcherThrows', value: 'L' },
        { name: 'pitchType', value: [] },
        { name: 'minX', update: "pitcherThrows == 'L' || pitcherThrows == 'Left' ? -4.5 : -1" },
        { name: 'maxX', update: "pitcherThrows == 'L' || pitcherThrows == 'Left' ? 1 : 4.5" },
        { name: 'minY', value: 0 },
        { name: 'maxY', value: 8 },
        {
            name: 'showPitchNumbers',
            value: false,
        },
        {
            name: 'actualPitchSize',
            value: true,
        },
        {
            name: 'pitchSize',
            update: 'max(PI * pow((width / (maxX - minX)) * (0.125 / (showPitchNumbers || actualPitchSize ? 1 : 1)), 2), 64)',
        },
        { name: 'insufficientData', update: "length(data('data')) === 0" },
        { name: 'height', update: '(width * (maxY - minY)) / (maxX - minX)' },
        { name: 'filteredIds', value: null },
        { name: 'title' },
        { name: 'brushable', value: false },
        { name: 'selectable', value: true },
        { name: 'isInteractive', update: '!brushable && selectable' },
        { name: 'hoveredId', value: null },
        { name: 'selectedIds', value: null },
        { name: 'hasSelected', update: '(selectedIds && length(selectedIds)) || (brushedIds && length(brushedIds))' },
        {
            name: 'gScale',
            value: {
                field: 'pitchType',
                label: {
                    name: 'pitchTypeAbbrev',
                    type: 'ordinal',
                    domain: map(pitchTypes, 'name'),
                    range: map(pitchTypes, (datum) => datum.abbrev || datum.value),
                },
                color: {
                    name: 'pitchTypeColor',
                    type: 'ordinal',
                    domain: map(pitchTypes, 'name'),
                    range: map(pitchTypes, 'color'),
                },
            },
        },
        {
            name: 'hovered',
            value: null,
            on: [
                {
                    events: '@visibleMarks:mouseover',
                    update: '!brushed && group().datum && ((brushedIds && indexof(brushedIds, group().datum.id) !== -1) || (selectedIds && indexof(selectedIds, group().datum.id) !== -1) || (!selectedIds && !brushedIds)) ? group().datum.id : null',
                },
                { events: '@visibleMarks:mouseout', update: 'null' },
            ],
        },
        {
            name: 'selectedId',
            value: null,
            on: [
                {
                    events: [
                        {
                            merge: [
                                {
                                    type: 'mousedown',
                                    markname: 'visibleMarks',
                                },
                                {
                                    type: 'touchstart',
                                    markname: 'visibleMarks',
                                },
                            ],
                        },
                    ],
                    update: 'group().datum ? group().datum.id : null',
                    force: true,
                },
            ],
        },
        {
            name: 'brushStart',
            value: null,
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                        },
                        {
                            type: 'touchstart',
                            consume: true,
                        },
                    ],
                    update: 'brushable ? { x: clamp(x(), 0, width), y: clamp(y(), 0, height) } : null',
                },
            ],
        },
        {
            name: 'brushEnd',
            value: {},
            on: [
                {
                    events: 'mousedown, [mousedown, window:mouseup] > window:mousemove, touchstart, [touchstart, window:touchend] > window:touchmove',
                    update: 'brushable ? { x: clamp(x(), 0, width), y: clamp(y(), 0, height) } : {}',
                },
            ],
        },
        {
            name: 'brushed',
            value: false,
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                        },
                        {
                            type: 'touchstart',
                        },
                    ],
                    update: '!selectable && brushable ? true : false',
                },
                {
                    events: 'window:mouseup, window:touchend',
                    update: 'false',
                },
            ],
        },
        {
            name: 'brushX',
            update: `brushStart ? extent(invert('x', [brushStart.x, brushEnd.x])) : []`,
        },
        {
            name: 'brushY',
            update: `brushStart ? extent(invert('y', [brushStart.y, brushEnd.y])) : []`,
        },
        {
            name: 'brushOperation',
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                        },
                        {
                            type: 'touchstart',
                            consume: true,
                        },
                    ],
                    update: "event.shiftKey && !event.altKey ? 'add' : event.altKey && !event.shiftKey ? 'remove' : null",
                },
            ],
        },
        { name: 'brushedIds', value: null, react: true },
        { name: 'currentId', value: null },
    ],
    data: [
        {
            name: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: '(length(pitchType) === 0 || indexof(pitchType, datum.pitchType) >= 0) && isNumber(datum.releaseHeight) && isNumber(datum.releaseSide)',
                },
            ],
            values: [],
        },
        {
            name: 'visibleData',
            source: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: '!filteredIds || (filteredIds && length(filteredIds) > 0 && indexof(filteredIds, datum.id) >= 0)',
                },
            ],
        },
        {
            name: 'hiddenData',
            source: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: 'filteredIds && (length(filteredIds) == 0 || indexof(filteredIds, datum.id) == -1)',
                },
            ],
        },
        {
            name: 'brushedData',
            source: 'visibleData',
            transform: [
                {
                    type: 'filter',
                    expr: 'isDefined(datum.releaseSide) && isDefined(datum.releaseHeight)',
                },
                {
                    type: 'filter',
                    expr: 'inrange(datum.releaseSide, brushX) && inrange(datum.releaseHeight, brushY)',
                },
            ],
        },
    ],
    scales: [
        {
            name: 'x',
            type: 'linear',
            domain: [{ signal: 'minX' }, { signal: 'maxX' }],
            nice: true,
            zero: false,
            range: 'width',
            reverse: { signal: 'perspective === "batter"' },
        },
        {
            name: 'y',
            type: 'linear',
            domain: [{ signal: 'minY' }, { signal: 'maxY' }],
            nice: true,
            zero: false,
            range: 'height',
        },
        {
            name: 'pitchColor',
            type: 'ordinal',
            domain: map(pitchTypes, 'name'),
            range: map(pitchTypes, 'color'),
        },
        {
            name: 'gColor',
            type: 'ordinal',
            domain: { signal: 'gScale.color.domain' },
            range: { signal: 'gScale.color.range' },
        },
        {
            name: 'gLabel',
            type: 'ordinal',
            domain: { signal: 'gScale.label.domain' },
            range: { signal: 'gScale.label.range' },
        },
    ],
    axes: [
        {
            orient: 'bottom',
            scale: 'x',
            grid: true,
            labels: true,
            tickCount: { signal: 'maxX - minX' },
            title: { signal: 'title' },
            encode: {
                labels: {
                    update: {
                        text: {
                            signal: "stlcFeetAndInches(datum.value, { input: 'feet', output: 'feet' })",
                        },
                    },
                },
            },
        },
        {
            orient: 'right',
            scale: 'y',
            grid: true,
            labels: true,
            tickCount: 8,
            encode: {
                labels: {
                    update: {
                        text: {
                            signal: "stlcFeetAndInches(datum.value, { input: 'feet', output: 'feet' })",
                        },
                    },
                },
            },
        },
    ],
    marks: [
        {
            type: 'rule',
            encode: {
                enter: {
                    y: { value: 0 },
                    stroke: { value: '#CCCCCC' },
                    strokeWidth: { value: 2 },
                },
                update: {
                    x: { value: 0, scale: 'x' },
                    y2: { field: { group: 'height' } },
                },
            },
        },
        {
            type: 'rule',
            encode: {
                enter: {
                    x: { value: 0 },
                    stroke: { value: '#CCCCCC' },
                    strokeWidth: { value: 2 },
                },
                update: {
                    x2: { field: { group: 'width' } },
                    y: { value: 0, scale: 'y' },
                },
            },
        },
        {
            type: 'rect',
            encode: {
                enter: {
                    fill: { value: '#FFFFFF' },
                    stroke: { value: '#CCCCCC' },
                    strokeWidth: { value: 1 },
                },
                update: {
                    x: { value: -1, scale: 'x' },
                    x2: { value: 1, scale: 'x' },
                    y: { value: 0, scale: 'y' },
                    y2: { value: 0.08333333333333333, scale: 'y' },
                },
            },
        },
        {
            name: 'hiddenMarks',
            from: { data: 'hiddenData' },
            type: 'symbol',
            interactive: false,
            encode: {
                enter: {
                    shape: { value: 'circle' },
                    fill: { value: '#cccccc' },
                    fillOpacity: { value: 0.2 },
                },
                update: {
                    x: { scale: 'x', field: 'releaseSide' },
                    y: { scale: 'y', field: 'releaseHeight' },
                    size: { signal: 'pitchSize' },
                },
            },
        },
        {
            type: 'group',
            from: { data: 'visibleData' },
            encode: {
                update: {
                    zindex: {
                        signal: 'currentId && currentId == datum.id ? 2 : hoveredId && hoveredId == datum.id ? 1 : 0',
                    },
                },
            },
            signals: [
                {
                    name: 'isBrushedOrSelected',
                    update: '(brushedIds && indexof(brushedIds, parent.id) !== -1) || (selectedIds && indexof(selectedIds, parent.id) !== -1)',
                },
                { name: 'isHidden', update: '(brushedIds || selectedIds) && !isBrushedOrSelected' },
                {
                    name: 'isSelectable',
                    update: 'selectable && !isHidden && isArray(parent.videos) && length(parent.videos) > 0',
                },
            ],
            marks: [
                {
                    type: 'symbol',
                    interactive: false,
                    encode: {
                        update: {
                            x: { scale: 'x', field: { parent: 'releaseSide' } },
                            y: { scale: 'y', field: { parent: 'releaseHeight' } },
                            size: {
                                signal: 'pow((sqrt(pitchSize / PI) + 3), 2) * PI',
                            },
                            stroke: { scale: 'gColor', signal: 'parent[gScale.field]' },
                            strokeWidth: {
                                signal: 'min(max(1, pow((sqrt(pitchSize / PI) + 3), 2) * PI / 32), 2)',
                            },
                            strokeOpacity: { value: 1 },
                            fill: { value: 'white' },
                            opacity: {
                                signal: '(currentId && parent.id == currentId) || (hoveredId && hoveredId == parent.id) ? 1 : 0',
                            },
                        },
                    },
                },
                {
                    name: 'visibleMarks',
                    type: 'symbol',
                    interactive: { signal: 'isInteractive && !isHidden' },
                    encode: {
                        update: {
                            x: { scale: 'x', field: { parent: 'releaseSide' } },
                            y: { scale: 'y', field: { parent: 'releaseHeight' } },
                            size: { signal: 'pitchSize' },
                            fill: { scale: 'gColor', signal: "isHidden ? 'Unknown' : parent[gScale.field]" },
                            fillOpacity: { signal: 'isHidden ? 0.2 : (hoveredId && hoveredId == parent.id) ? 1 : 0.5' },
                            stroke: { scale: 'gColor', signal: 'parent[gScale.field]' },
                            strokeOpacity: { signal: 'isBrushedOrSelected ? 1 : 0.75' },
                            strokeWidth: { signal: 'isBrushedOrSelected ? 1 : 0' },
                            tooltip: { signal: 'isInteractive && !isHidden ? parent : null' },
                            cursor: { signal: "isSelectable ? 'pointer' : 'default'" },
                        },
                    },
                },
            ],
        },
        {
            name: 'brush',
            type: 'rect',
            interactive: false,
            encode: {
                enter: {
                    fill: { value: '#0080FF' },
                    fillOpacity: { value: 0.1 },
                    strokeWidth: { value: 1 },
                    stroke: { value: '#0080FF' },
                    strokeOpacity: { value: 0.3 },
                },
                update: {
                    x: { signal: 'brushStart ? brushStart.x : null' },
                    x2: { signal: 'brushEnd.x' },
                    y: { signal: 'brushStart ? brushStart.y : null' },
                    y2: { signal: 'brushEnd.y' },
                    fillOpacity: { signal: 'brushed ? 0.1 : 0' },
                    strokeOpacity: { signal: 'brushed ? 0.3 : 0' },
                },
            },
        },
    ],
};
