<div class="relative">
    <div class="flex justify-end sticky top-16 z-100 h-4">
        <div class="absolute">
            <ng-template *ngTemplateOutlet="brushingButtonsTemplate"></ng-template>
        </div>
    </div>
    <div class="flex flex-col items-center space-y-4">
        <ui-chip-list
            chipColor="none"
            [required]="true"
            [options]="service.viewOptions"
            [disabled]="loading"
            (selectedChange)="viewChange($event)"
        ></ui-chip-list>
        <div class="w-full" [ngSwitch]="service.selectedView$ | async">
            <ng-container *ngSwitchCase="ChartView.Pitch">
                <player-charts-ui-games-batting-pitch-charts
                    [pitches]="service.pitches$ | async"
                    [filteredIds]="service.filteredIds$ | async"
                    [enableBrushing]="chartBrushable"
                ></player-charts-ui-games-batting-pitch-charts>
            </ng-container>
            <ng-container *ngSwitchCase="ChartView.Contact">
                <player-charts-ui-games-batting-contact-charts
                    [pitches]="service.pitchesWithContact$ | async"
                    [filteredIds]="service.filteredIds$ | async"
                    [enableBrushing]="chartBrushable"
                ></player-charts-ui-games-batting-contact-charts>
            </ng-container>
        </div>
    </div>
</div>

<div class="flex flex-col items-center mx-auto mt-8 space-y-4">
    <div class="flex justify-center">
        <ui-chip-list
            chipStyle="checkbox"
            [options]="service.pitchTypeOptions$ | async"
            [multiple]="true"
            [disabled]="loading"
            (selectedChange)="pitchTypesChange($event)"
        ></ui-chip-list>
    </div>
    <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 space-x-0 sm:space-x-8 sm:mx-auto">
        <div class="flex justify-around space-x-8">
            <ui-chip-list
                [options]="service.pitcherThrowsOptions"
                [disabled]="loading"
                (selectedChange)="pitcherThrowsChange($event)"
            ></ui-chip-list>
            <player-charts-ui-games-count-filter
                [disabled]="loading"
                [selected]="service.selectedCounts$ | async"
                (valueChange)="countsChange($event)"
            ></player-charts-ui-games-count-filter>
        </div>
        <div class="flex justify-around space-x-8">
            <ui-chip-select
                prefix="Result"
                placeholder="Result"
                multiple="true"
                [chipColor]="(service.selectedPitchResults$ | async) === undefined ? 'default' : 'primary'"
                [options]="service.pitchResultOptions"
                [disabled]="loading"
                (selectedChange)="pitchResultsChange($event)"
            ></ui-chip-select>
            <ui-chip-select
                prefix="Runners"
                placeholder="Runners"
                multiple="true"
                [chipColor]="(service.selectedBasesOccupied$ | async) === undefined ? 'default' : 'primary'"
                [options]="service.basesOccupiedOptions"
                [disabled]="loading"
                (selectedChange)="basesOccupiedChange($event)"
            ></ui-chip-select>
        </div>
    </div>
    <ng-container *ngIf="service.otherFiltersOptions$ | async as otherFiltersOptions">
        <div class="flex justify-center" *ngIf="otherFiltersOptions.length !== 0">
            <ui-chip-list
                [options]="otherFiltersOptions"
                [multiple]="true"
                [disabled]="loading"
                (selectedChange)="otherFiltersChange($event)"
            ></ui-chip-list>
        </div>
    </ng-container>
    <button
        mat-button
        [disabled]="loading"
        [class.invisible]="service.resetFilterDisabled$ | async"
        (click)="resetFilters()"
        type="button"
    >
        {{ 'filters:resetFilters' | i18next : { defaultValue: 'Reset Filters' } }}
    </button>
</div>

<div *ngIf="service.isVideoAvailable$ | async" class="stlc-fab-bottom my-4 sticky">
    <button mat-fab extended color="primary" (click)="openVideoDialog()" type="button">
        <mat-icon>play_arrow</mat-icon>
        {{ 'common:watchVideo' | i18next }}
    </button>
</div>

<div class="mt-10 mb-8" *ngIf="service.selectedGames$ | async as games" [ngSwitch]="!games || games.length === 0">
    <ng-container *ngSwitchCase="true">
        <div *ngIf="loading" class="flex justify-center h-40">
            <mat-spinner diameter="50"></mat-spinner>
        </div>
    </ng-container>

    <player-stats-game-pitches-card
        seasonType="batting"
        [games]="games"
        [disabled]="loading"
        [pageSize]="150"
        (videoSelect)="openVideoDialog($event)"
    ></player-stats-game-pitches-card>
</div>

<ng-template #brushingButtonsTemplate>
    <div class="flex space-x-4 pr-4">
        <button
            *ngIf="brushingService.hasSelection$ | async"
            mat-mini-fab
            type="button"
            color="default"
            [disabled]="loading"
            (click)="clearChartSelected($event)"
            title="Clear Selection"
        >
            <mat-icon>clear</mat-icon>
        </button>
        <button
            mat-mini-fab
            color="default"
            [color]="chartBrushable ? 'primary' : 'default'"
            [attr.title]="chartBrushable ? 'Cancel Highlighting' : 'Highlight Pitches'"
            [disabled]="loading"
            (click)="toggleChartBrushable($event)"
        >
            <mat-icon>{{ (appService.isTouchDevice$ | async) === true ? 'touch_app' : 'ads_click' }}</mat-icon>
        </button>
    </div>
</ng-template>
