import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import dayjs from 'dayjs';
import { take } from 'rxjs';
import type { View } from 'vega';

import { StlcI18nModule } from '@stlc/i18n/core';
import { assign, find, forEach, map } from '@stlc/lodash';
import { PlayerGamePitchFragment } from '@stlc/player-charts/data-access';
import { StlcSelectOption } from '@stlc/shared';
import { UiIconDropdownComponent } from '@stlc/ui';
import { UiVegaChartComponent } from '@stlc/ui/vega';
import { UserService } from '@stlc/user';

import { overheadContactSpec, spraySpec, strikeZoneSpec } from '../../specs';
import { PlayerChartsUiBattingTooltipComponent } from '../batting-tooltip';

@Component({
    selector: 'player-charts-ui-games-batting-contact-charts',
    templateUrl: './games-batting-contact-charts.component.html',
    styleUrls: ['./games-batting-contact-charts.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatCheckboxModule,
        MatMenuModule,
        MatFormFieldModule,
        MatSelectModule,
        PlayerChartsUiBattingTooltipComponent,
        StlcI18nModule,
        UiIconDropdownComponent,
        UiVegaChartComponent,
    ],
})
export class PlayerChartsUiGamesBattingContactChartsComponent implements OnChanges, OnInit {
    @Input()
    // pitches: PlayerGamePitchFragment[] = [];
    set pitches(values: PlayerGamePitchFragment) {
        this.data = map(values, (pitch, index) => assign(pitch, { pitchNumber: index + 1 }));
    }

    @Input()
    filteredIds: string[] | null = null;

    @Input()
    enableBrushing = false;

    charts = {
        contact: {
            spec: overheadContactSpec,
            signals: {
                //filteredIds: null,
                pitchTypes: null,
                perspective: 'batter',
            },
        },
        strikeZone: {
            spec: strikeZoneSpec,
            signals: {
                chartType: 'contact',
                //filteredIds: null,
                pitchTypes: null,
                perspective: 'batter',
            },
        },
        spray: {
            spec: spraySpec,
            signals: {
                //filteredIds: null,
                pitchTypes: null,
                showCircles: true,
                showLines: true,
            },
        },
    };

    data: PlayerGamePitchFragment[] = [];
    contactView?: View;
    strikeZoneView?: View;
    sprayView?: View;
    showCircles = true;
    showLines = true;

    readonly perspectiveOptions: StlcSelectOption[] = [
        {
            text: "Pitcher's Perspective",
            textKey: 'chart:pitcherPerspective_label',
            value: 'pitcher',
        },
        {
            text: "Batter's Perspective",
            textKey: 'chart:batterPerspective_label',
            value: 'batter',
        },
    ];
    selectedPerspective?: StlcSelectOption;

    // perspective: 'batter' | 'pitcher' = 'batter';
    perspectivePreferenceKey = 'player-charts:games:batting:perspective';
    sprayChartPreferenceKey = 'player-charts:games:batting:spray-chart';

    private readonly userService = inject(UserService);

    ngOnInit(): void {
        this.userService
            .getPreferenceValue$(this.perspectivePreferenceKey)
            .pipe(take(1))
            .subscribe((perspective?: string) => {
                this.selectedPerspective = find(this.perspectiveOptions, { value: perspective ?? 'batter' });
                this.updatePerspective(this.selectedPerspective.value);
            });

        this.userService
            .getPreferenceValue$(this.sprayChartPreferenceKey)
            .pipe(take(1))
            .subscribe((preference?: string) => {
                if (preference === 'lines') {
                    this.updateSprayChartCircles(false);
                } else if (preference === 'circles') {
                    this.updateSprayChartLines(false);
                } else if (preference === 'neither') {
                    this.updateSprayChartLines(false);
                    this.updateSprayChartCircles(false);
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['filteredIds']) {
            forEach([this.contactView, this.strikeZoneView, this.sprayView], (view) => {
                if (view) {
                    view.signal('filteredIds', this.filteredIds).runAsync();
                }
            });
        }
    }

    contactRendered(view: View): void {
        this.contactView = view;
        this.contactView.signal('filteredIds', this.filteredIds).runAsync();
    }

    strikeZoneRendered(view: View): void {
        this.strikeZoneView = view;
        this.strikeZoneView.signal('filteredIds', this.filteredIds).runAsync();
    }

    sprayChartRendered(view: View): void {
        this.sprayView = view;
        this.sprayView.signal('filteredIds', this.filteredIds).runAsync();
    }

    changePerspective(change: MatSelectChange): void {
        this.selectedPerspective = change.value;
        const perspective = this.selectedPerspective?.value;
        this.updatePerspective(perspective);
        this.userService.updatePreference(this.perspectivePreferenceKey, perspective);
    }

    toggleShowSprayChartCircles({ checked }: MatCheckboxChange): void {
        // if ($event) {
        //     $event.preventDefault();
        //     $event.stopImmediatePropagation();
        // }
        this.updateSprayChartCircles(checked);
        this.updateSprayPreference();
    }

    toggleShowSprayChartLines({ checked }: MatCheckboxChange): void {
        // if ($event) {
        //     $event.preventDefault();
        //     $event.stopImmediatePropagation();
        // }

        this.updateSprayChartLines(checked);
        this.updateSprayPreference();
    }

    private updatePerspective(perspective: string) {
        this.charts = {
            ...this.charts,
            contact: {
                ...this.charts.contact,
                signals: {
                    ...this.charts.contact.signals,
                    perspective,
                },
            },
            strikeZone: {
                ...this.charts.strikeZone,
                signals: {
                    ...this.charts.strikeZone.signals,
                    perspective,
                },
            },
        };
    }

    private updateSprayChartCircles(newVal: boolean): void {
        this.showCircles = newVal;
        this.charts = {
            ...this.charts,
            spray: {
                ...this.charts.spray,
                signals: {
                    ...this.charts.spray.signals,
                    showCircles: this.showCircles,
                },
            },
        };
    }

    private updateSprayChartLines(newVal: boolean): void {
        this.showLines = newVal;
        this.charts = {
            ...this.charts,
            spray: {
                ...this.charts.spray,
                signals: {
                    ...this.charts.spray.signals,
                    showLines: this.showLines,
                },
            },
        };
    }

    private updateSprayPreference() {
        if (this.showLines && this.showCircles) {
            this.userService.updatePreference(this.sprayChartPreferenceKey, 'both');
        } else if (this.showLines) {
            this.userService.updatePreference(this.sprayChartPreferenceKey, 'lines');
        } else if (this.showCircles) {
            this.userService.updatePreference(this.sprayChartPreferenceKey, 'circles');
        } else {
            this.userService.updatePreference(this.sprayChartPreferenceKey, 'neither');
        }
    }
}
