import { inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { I18NEXT_SERVICE } from 'angular-i18next';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';

import { StlcNumberToOrdinalPipe } from '@stlc/angular/pipes/number-to-ordinal';
import { StlcI18nGameEventPipe } from '@stlc/i18n/core';
import {
    chain,
    compact,
    filter as _filter,
    find,
    forEach,
    head,
    includes,
    isArray,
    isEmpty,
    isNil,
    map as _map,
    pick,
    some,
    transform,
} from '@stlc/lodash';
import { getPitchTypeAbbrev, getPitchTypeCode, getPitchTypeDescription, getPitchTypeOrder } from '@stlc/lookup/legacy';
import { getStatSelectOption } from '@stlc/lookup/stat';
import type { PlayerProfilePlayer } from '@stlc/player/shared';
import {
    PitchTypeSelectOptionFragment,
    PlayerChartsGamesQueryParams,
    PlayerGameFragment,
    PlayerGamePitchFragment,
    PlayerStatsHandedness,
    PlayerStatsPitchType,
} from '@stlc/player-charts/data-access';
import { StlcSelectOption, StlcStat, StlcStatSelectOption, UiVideoDialogEvent } from '@stlc/shared';
import { UiVegaBrushingService } from '@stlc/ui/vega';

dayjs.extend(customParseFormat);

const stats = [
    StlcStat.ReleaseSpeed,
    StlcStat.ReleaseExtension,
    StlcStat.ReleaseHeight,
    StlcStat.ReleaseSide,
    StlcStat.ReleaseVerticalAngle,
    StlcStat.ReleaseHorizontalAngle,
    StlcStat.ReleaseSpinRate,
    StlcStat.InducedVerticalBreak,
    StlcStat.HorizontalBreak,
    StlcStat.PlateHeight,
    StlcStat.PlateSide,
    StlcStat.VerticalApproachAngle,
    StlcStat.HorizontalApproachAngle,
];

@Injectable()
export class PlayerChartsUiGamesPitchingService implements OnDestroy {
    gamesSubject = new BehaviorSubject<PlayerGameFragment[] | undefined>(undefined);
    games$: Observable<PlayerGameFragment[]>;
    set games(val: PlayerGameFragment[]) {
        this.gamesSubject.next(val);
    }

    playerSubject = new BehaviorSubject<PlayerProfilePlayer | undefined>(undefined);
    private player$: Observable<PlayerProfilePlayer>;
    set player(val: PlayerProfilePlayer) {
        this.playerSubject.next(val);
    }

    readonly gamesWithContact$: Observable<PlayerGameFragment[]>;
    readonly selectedGames$: Observable<PlayerGameFragment[]>;
    readonly pitches$: Observable<PlayerGamePitchFragment[]>;
    readonly selectedPitches$: Observable<PlayerGamePitchFragment[]>;
    readonly pitchesWithContact$: Observable<PlayerGamePitchFragment[]>;
    readonly videoEvents$: Observable<UiVideoDialogEvent[]> | undefined;
    readonly isVideoAvailable$: Observable<boolean>;
    readonly hasEdgertronic$: Observable<boolean>;

    readonly statOptions = _map(stats, (stat) => getStatSelectOption(stat));

    // Filtering ONLY
    readonly filteredIds$: Observable<string[]>;
    readonly resetFilterDisabled$: Observable<boolean>;
    readonly pitchTypeOptions$: Observable<PitchTypeSelectOptionFragment[]>;
    readonly selectedPitchTypes$: Observable<PlayerStatsPitchType[]>;
    readonly batterBatsOptions: StlcSelectOption<PlayerStatsHandedness>[] = [
        {
            text: 'vs. RHB',
            textKey: 'filters:batterHandednessRight_label',
            value: PlayerStatsHandedness.Right,
        },
        {
            text: 'vs. LHB',
            textKey: 'filters:batterHandednessLeft_label',
            value: PlayerStatsHandedness.Left,
        },
    ];
    readonly selectedBatterBats$: Observable<PlayerStatsHandedness | undefined>;
    readonly selectedCounts$: Observable<string[] | undefined>;
    readonly selectedStatOption$: Observable<StlcStatSelectOption | undefined>;
    pitchResultOptions: StlcStatSelectOption[] = [
        {
            text: 'All Results',
            textKey: 'filters:pitchResultAll_label',
            value: undefined,
        },
        {
            text: 'Ball',
            textKey: 'filters:pitchResultBall_label',
            value: 'B',
            divider: true,
        },
        {
            text: 'Called Strike',
            textKey: 'filters:pitchResultCalledStrike_label',
            value: 'SC',
        },
        {
            text: 'Swinging Strike',
            textKey: 'filters:pitchResultSwingingStrike_label',
            value: 'SS',
        },
        {
            text: 'Foul',
            textKey: 'filters:pitchResultFoul_label',
            value: 'F',
        },
        {
            text: 'In Play',
            textKey: 'filters:pitchResultInPlay_label',
            value: 'IP',
        },
    ];
    readonly selectedPitchResults$: Observable<string[] | undefined>;
    basesOccupiedOptions: StlcSelectOption<string | null>[] = [
        {
            text: 'Empty',
            textKey: 'filters:empty_label',
            value: '',
            attributes: null,
        },
        {
            text: 'RISP',
            textKey: 'filters:risp_label',
            value: 'risp',
            attributes: ['2', '23', '3', '13', '12', '123'],
        },
        {
            text: '1st',
            textKey: 'filters:runners_first',
            value: '1',
            divider: true,
        },
        {
            text: '1st & 2nd',
            textKey: 'filters:runners_first_second',
            value: '12',
        },
        {
            text: '1st & 3rd',
            textKey: 'filters:runners_first_third',
            value: '13',
        },
        {
            text: '2nd',
            textKey: 'filters:runners_second',
            value: '2',
        },
        {
            text: '2nd & 3rd',
            textKey: 'filters:runners_second_third',
            value: '23',
        },
        {
            text: '3rd',
            textKey: 'filters:runners_third',
            value: '3',
        },
        {
            text: 'Full',
            textKey: 'filters:runners_full',
            value: '123',
        },
    ];
    readonly selectedBasesOccupied$: Observable<(string | null)[] | undefined>;
    pitcherThrows$: Observable<string>;

    readonly otherFiltersOptions$: Observable<StlcSelectOption<string>[]>;
    readonly selectedOtherFilters$: Observable<string[] | undefined>;

    private stlcI18nGameEventPipe: StlcI18nGameEventPipe;
    private ordinalPipe: StlcNumberToOrdinalPipe = new StlcNumberToOrdinalPipe();

    private readonly destroy = new Subject<void>();

    private readonly i18next = inject(I18NEXT_SERVICE);
    private readonly brushingService = inject(UiVegaBrushingService);
    private readonly route = inject(ActivatedRoute);

    constructor() {
        this.stlcI18nGameEventPipe = new StlcI18nGameEventPipe(this.i18next);
        this.games$ = this.gamesSubject.asObservable();
        this.player$ = this.route.data.pipe(
            map((datum) => datum['player'] as PlayerProfilePlayer | undefined),
            filter((player) => !isNil(player)),
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            shareReplay(1)
        );
        this.pitcherThrows$ = this.player$.pipe(
            filter((player) => !isNil(player)),
            map(({ throws }) => throws),
            distinctUntilChanged()
        );

        this.pitches$ = this.games$.pipe(
            map((games) => chain(games).map('pitches').compact().flatten().valueOf()),
            shareReplay(1)
        );

        this.hasEdgertronic$ = this.pitches$.pipe(
            map((pitches) =>
                chain(pitches)
                    .map('videos')
                    .flatten()
                    .some((video) => video.videoAngle?.startsWith('Edger'))
                    .valueOf()
            )
        );

        this.otherFiltersOptions$ = combineLatest([
            this.hasEdgertronic$,
            this.route.queryParams.pipe(map((queryParams) => queryParams[PlayerChartsGamesQueryParams.Other] ?? [])),
        ]).pipe(
            map(([hasEdgertronic, selectedOther]) =>
                compact([
                    hasEdgertronic
                        ? {
                              textKey: 'filters:edgertronic',
                              text: 'Edgertronic',
                              value: 'edgertronic',
                              selected:
                                  (isArray(selectedOther) && includes(selectedOther, 'edgertronic')) ||
                                  selectedOther === 'edgertronic',
                          }
                        : undefined,
                ])
            )
        );

        this.pitchTypeOptions$ = combineLatest([
            this.route.queryParams.pipe(map((queryParams) => queryParams[PlayerChartsGamesQueryParams.PitchType])),
            this.pitches$.pipe(
                map((pitches) => chain(pitches).uniqBy('pitchType').map('pitchType').compact().valueOf())
            ),
        ]).pipe(
            map(([selected, pitchTypes]) => {
                if (selected && !isArray(selected)) {
                    selected = [selected];
                }

                return chain(pitchTypes)
                    .map((pitchType) => {
                        // const code = getPitchTypeCode(pitchType);
                        const abbreviation = getPitchTypeAbbrev(pitchType);
                        return {
                            text: getPitchTypeDescription(pitchType),
                            textKey: `pitchType:${abbreviation}_label`,
                            value: pitchType,
                            pitchType,
                            color: `pitch-${abbreviation.toLowerCase()}`,
                            selected: selected && selected.length > 0 && includes(selected, pitchType),
                            abbreviation,
                        };
                    })
                    .orderBy((option) => getPitchTypeOrder(option.pitchType))
                    .valueOf();
            })
        );

        this.selectedPitchTypes$ = combineLatest([
            this.route.queryParams.pipe(
                map((queryParams) => queryParams[PlayerChartsGamesQueryParams.PitchType] ?? [])
            ),
            this.pitchTypeOptions$,
        ]).pipe(
            map(([pitchTypes, options]) => {
                forEach(options, (option) => {
                    option.selected = includes(pitchTypes ?? [], option.pitchType);
                });

                return options.filter(({ selected }) => selected).map((option) => option.pitchType);
            }),
            shareReplay(1)
        );

        this.selectedBatterBats$ = this.route.queryParams.pipe(
            map((queryParams) => queryParams[PlayerChartsGamesQueryParams.BatterBats]),
            distinctUntilChanged(),
            map((batterBats) => {
                forEach(this.batterBatsOptions, (option) => {
                    option.selected = batterBats === option.value;
                });

                return this.batterBatsOptions.find(({ selected }) => selected)?.value;
            }),
            shareReplay(1)
        );

        this.selectedCounts$ = this.route.queryParams.pipe(
            map((queryParams) => queryParams[PlayerChartsGamesQueryParams.Count]),
            distinctUntilChanged(),
            shareReplay(1)
        );

        this.selectedStatOption$ = this.route.queryParams.pipe(
            map((queryParams) => queryParams[PlayerChartsGamesQueryParams.Stat]),
            distinctUntilChanged(),
            map((stat) => {
                forEach(this.statOptions, (option) => {
                    option.selected = stat === option.value;
                });

                // Ensure first stat is selected
                let selectedStat = this.statOptions.find(({ selected }) => selected);
                if (!selectedStat) {
                    selectedStat = head(this.statOptions);
                    if (selectedStat) {
                        selectedStat.selected = true;
                    }
                }

                return selectedStat;
            }),
            shareReplay(1)
        );

        this.selectedPitchResults$ = this.route.queryParams.pipe(
            map((queryParams) => {
                const results = queryParams[PlayerChartsGamesQueryParams.PitchResult];
                // forEach(this.pitchResultOptions, (option) => {
                //     option.selected = includes(results, option.value);
                // });
                this.pitchResultOptions = _map(this.pitchResultOptions, (option) => ({
                    ...option,
                    selected: includes(results, option.value),
                }));

                if (isEmpty(results)) {
                    return undefined;
                }

                return this.pitchResultOptions
                    .filter(({ selected }) => selected)
                    .map((option) => option.value) as string[];
            }),
            distinctUntilChanged(),
            shareReplay(1)
        );

        this.selectedBasesOccupied$ = this.route.queryParams.pipe(
            map((queryParams) => {
                let values = queryParams[PlayerChartsGamesQueryParams.BasesOccupied];
                if (!isNil(values) && !isArray(values)) {
                    values = [values];
                }
                const basesOccupied = chain(this.basesOccupiedOptions)
                    .filter((option) => includes(values, option.value))
                    .map((option) =>
                        option.attributes || option.attributes === null ? option.attributes : option.value
                    )
                    .flatten()
                    .uniq()
                    .valueOf();

                // forEach(this.basesOccupiedOptions, (option) => {
                //     option.selected = includes(values, option.value);
                // });
                this.basesOccupiedOptions = _map(this.basesOccupiedOptions, (option) => ({
                    ...option,
                    selected: includes(values, option.value),
                }));

                return isEmpty(basesOccupied) ? undefined : basesOccupied;
            }),
            distinctUntilChanged(),
            shareReplay(1)
        );

        this.selectedOtherFilters$ = combineLatest([this.route.queryParams, this.otherFiltersOptions$]).pipe(
            map(([queryParams, otherFiltersOptions]) => {
                const queryParam = queryParams[PlayerChartsGamesQueryParams.Other];
                if (isArray(queryParam)) {
                    return _filter(queryParam, (option) => some(otherFiltersOptions, ({ value }) => option === value));
                }
                return find(otherFiltersOptions, (option) => option.value === queryParam) ? [queryParam] : [];
            }),
            distinctUntilChanged(),
            shareReplay(1)
        );

        this.filteredIds$ = combineLatest([
            this.pitches$,
            this.selectedPitchTypes$,
            this.selectedBatterBats$,
            this.selectedCounts$,
            this.selectedPitchResults$,
            this.selectedBasesOccupied$,
            this.selectedOtherFilters$,
        ]).pipe(
            map(([pitches, pitchTypes, batterBats, counts, results, basesOccupied, otherFilters]) => {
                if (pitchTypes && pitchTypes.length > 0) {
                    pitches = _filter(pitches, (pitch) => includes(pitchTypes, pitch.pitchType));
                }
                if (batterBats) {
                    pitches = _filter(pitches, (pitch) => pitch.batterBats === batterBats);
                }
                if (!isEmpty(counts)) {
                    pitches = _filter(pitches, (pitch) => includes(counts, `${pitch.preBalls}-${pitch.preStrikes}`));
                }
                if (!isEmpty(results)) {
                    pitches = _filter(pitches, (pitch) => includes(results, pitch.pitchResult));
                }
                if (!isEmpty(basesOccupied)) {
                    pitches = _filter(pitches, (pitch) => includes(basesOccupied, pitch.basesOccupied));
                }
                if (!isEmpty(otherFilters)) {
                    if (includes(otherFilters, 'edgertronic')) {
                        pitches = _filter(pitches, (pitch) =>
                            some(pitch.videos, (video) => video.videoAngle?.startsWith('Edger'))
                        );
                    }
                }
                return _map(pitches, 'id');
            }),
            shareReplay(1)
        );
        this.resetFilterDisabled$ = combineLatest([this.pitches$, this.filteredIds$]).pipe(
            map(([pitches, filteredIds]) => !isEmpty(filteredIds) && pitches.length === filteredIds.length),
            shareReplay(1)
        );

        this.selectedGames$ = combineLatest([this.games$, this.brushingService.selectedIds$, this.filteredIds$]).pipe(
            map(([games, brushedIds, filteredIds]) => {
                const ids = isEmpty(brushedIds) ? (isEmpty(filteredIds) ? [] : filteredIds) : brushedIds;
                if (!isEmpty(ids)) {
                    games = transform(games, (results: PlayerGameFragment[], game) => {
                        const pitches = _filter(game.pitches, (pitch) => includes(ids, `${pitch.id}`));
                        if (pitches.length > 0) {
                            results.push({
                                ...game,
                                pitches,
                            });
                        }
                    });
                } else {
                    return [];
                }

                return games;
            }),
            shareReplay(1)
        );

        this.selectedPitches$ = this.selectedGames$.pipe(
            map((games) => chain(games).map('pitches').compact().flatten().valueOf()),
            shareReplay(1)
        );

        this.videoEvents$ = this.selectedGames$.pipe(
            map((games) =>
                transform(games, (result: UiVideoDialogEvent[], game) =>
                    forEach(game.pitches, (datum) => {
                        const pitchType = datum?.pitchType ? getPitchTypeCode(datum.pitchType) : undefined;
                        result.push({
                            id: datum.id,
                            eventGroup: `${game?.id}:${datum.inning}:${datum.isTopOfInning}:${datum.inningPa}`,
                            title: `${datum.batter?.firstName} ${datum.batter?.lastName} vs ${datum.pitcher?.firstName} ${datum.pitcher?.lastName}`,
                            subtitles: [
                                datum.inning
                                    ? `${datum.isTopOfInning ? 'Top' : 'Bot'} ${this.ordinalPipe.transform(
                                          datum.inning
                                      )}`
                                    : '',
                                `${datum.preBalls}-${datum.preStrikes} ${
                                    !isNil(datum?.preOuts)
                                        ? this.i18next.t('game:out', { count: datum?.preOuts ?? 0 })
                                        : ''
                                }`,
                                pitchType ? this.i18next.t(`pitchType:${pitchType}_label`) : 'Unknown',
                                datum?.pitchOutcome
                                    ? this.stlcI18nGameEventPipe.transform({
                                          isFinalPlay: datum.isFinalPlay,
                                          isNextPlay: datum.isNextPlay,
                                          pitchResult: datum?.pitchResult,
                                          eventType: datum?.eventType,
                                          battedBallType: datum?.battedBallType,
                                      })
                                    : '',
                            ],
                            pitch: {
                                ...datum,
                                pitchType,
                                description: this.stlcI18nGameEventPipe.transform(datum),
                            },
                            date: game.gameDate ? dayjs(game.gameDate).format('dddd, MMMM D, YYYY') : undefined,
                            description: this.stlcI18nGameEventPipe.transform(datum),
                            playByPlay: datum?.playByPlayDescription || '',
                            videos: datum?.videos || [],
                            types: ['pitches'],
                        });
                    })
                )
            )
        );

        this.isVideoAvailable$ = this.videoEvents$.pipe(
            map((videoEvents) => !chain(videoEvents).map('videos').reject(isEmpty).isEmpty().valueOf())
        );
    }

    ngOnDestroy(): void {
        this.destroy.next();
    }
}
