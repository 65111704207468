import type { Spec } from 'vega';

import { map } from '@stlc/lodash';
import { getPitchTypeCode } from '@stlc/lookup/legacy';
import { getPitchTypeColor, playerStatsPitchTypes } from '@stlc/lookup/statcast';
import { rulebookZone } from '@stlc/vega/utils';

export const videoStrikeZoneSpec: Spec = {
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    width: 270,
    autosize: { type: 'none', resize: false, contains: 'padding' },
    usermeta: { resize: false },
    background: 'transparent',
    config: {
        title: {
            font: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            fontSize: 16,
            fontWeight: 'normal',
            color: '#333333',
        },
        axis: {
            domainColor: '#aaaaaa',
            domainOpacity: 1,
            gridColor: '#dddddd',
            gridOpacity: 1,
            labelColor: '#333333',
            labelFont: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            labelFontSize: 11,
            labelFontWeight: 'normal',
            tickColor: '#999999',
            titleFont: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            titleFontSize: 16,
            titleFontWeight: 'normal',
            titleColor: '#333333',
            titlePadding: 16,
        },
        text: {
            font: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
        },
        events: { defaults: { allow: ['wheel', 'touchstart'] } },
    },
    signals: [
        { name: 'defaultWidth', value: 270 },
        { name: 'defaultHeight', value: 270 },
        { name: 'height', update: '(width / defaultWidth) * defaultHeight' },
        { name: 'perspective', value: 'pitcher' },
        { name: 'zoneType', value: '9-box' },
        {
            name: 'label',
            value: {
                pitcher: 'Perspective: Pitcher',
                batter: 'Perspective: Batter',
            },
        },
        { name: 'batterBats' },
        { name: 'pitchTypes' },
        { name: 'pitchType' },
        { name: 'insufficientData', description: 'Not used' },
        { name: 'selectedIds' },
        { name: 'hasSelected', update: 'selectedIds && length(selectedIds)' },
        { name: 'showPitchNumbers', value: true },
        { name: 'actualPitchSize', value: true },
        { name: 'minX', value: -2.3 },
        { name: 'maxX', value: 2.3 },
        { name: 'minY', value: -0.1 },
        { name: 'maxY', value: 4.5 },
        {
            name: 'pitchSize',
            update: 'PI * pow((width / (maxX - minX)) * 0.125 / (showPitchNumbers || actualPitchSize ? 1 : 2), 2)',
        },
        { name: 'selectable', value: false },
        { name: 'brushable', value: false },
        { name: 'hoveredId', update: 'hovered' },
        {
            name: 'hovered',
            value: null,
            on: [
                { events: '@visibleMarks:mouseover', update: 'group().datum ? group().datum.id : null' },
                { events: '@visibleMarks:mouseout', update: 'null' },
            ],
        },
        { name: 'isInteractive', update: '!brushable && selectable' },
        {
            name: 'selectedId',
            value: null,
            on: [
                {
                    events: [
                        {
                            merge: [
                                {
                                    type: 'mousedown',
                                    markname: 'visibleMarks',
                                },
                                {
                                    type: 'touchstart',
                                    markname: 'visibleMarks',
                                },
                            ],
                        },
                    ],
                    update: 'isInteractive && group().datum ? group().datum.id : null',
                    force: true,
                },
            ],
        },
        { name: 'currentId', value: null },
    ],
    data: [
        {
            name: 'data',
            values: [],
            transform: [
                {
                    type: 'filter',
                    expr: "datum.pitchType && datum.pitchType !== 'unk' && datum.pitchType !== 'IN' && ((!pitchType && (!isArray(pitchTypes) || indexof(pitchTypes, datum.pitchType) >= 0)) || datum.pitchType == pitchType) && (!batterBats || datum.batterBats == batterBats)",
                },
            ],
        },
        {
            name: 'visibleData',
            source: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: '!selectedIds || (selectedIds && length(selectedIds) > 0 && indexof(selectedIds, datum.id) >= 0)',
                },
            ],
        },
        {
            name: 'hiddenData',
            source: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: 'selectedIds && (length(selectedIds) == 0 || indexof(selectedIds, datum.id) == -1)',
                },
            ],
        },
        { name: 'ellipsesData', values: [] },
        {
            name: 'contextData',
            values: [],
            transform: [
                {
                    type: 'filter',
                    expr: "datum.pitchType && datum.pitchType !== 'unk' && datum.pitchType !== 'IN' && ((!pitchType && (!isArray(pitchTypes) || indexof(pitchTypes, datum.pitchType) >= 0)) || datum.pitchType == pitchType) && (!batterBats || datum.batterBats == batterBats)",
                },
            ],
        },
        {
            name: 'context2Data',
            values: [],
            transform: [
                {
                    type: 'filter',
                    expr: "datum.pitchType && datum.pitchType !== 'unk' && datum.pitchType !== 'IN' && ((!pitchType && (!isArray(pitchTypes) || indexof(pitchTypes, datum.pitchType) >= 0)) || datum.pitchType == pitchType) && (!batterBats || datum.batterBats == batterBats)",
                },
            ],
        },
        {
            name: 'contextContour',
            source: 'contextData',
            transform: [
                { type: 'filter', expr: "length(data('contextData')) > 3" },
                {
                    type: 'contour',
                    x: { expr: "scale('x', datum.plateSide)" },
                    y: { expr: "scale('y', datum.plateHeight)" },
                    size: [{ signal: 'width' }, { signal: 'height' }],
                    count: 4,
                },
            ],
        },
        {
            name: 'homePlateData',
            values: [
                { perspective: 'pitcher', x: -0.70833, y: 0 },
                { perspective: 'pitcher', x: 0.70833, y: 0 },
                { perspective: 'pitcher', x: 0.694167, y: 0.08 },
                { perspective: 'pitcher', x: 0, y: 0.18 },
                { perspective: 'pitcher', x: -0.694167, y: 0.08 },
                { perspective: 'pitcher', x: -0.70833, y: 0 },
                { perspective: 'batter', x: -0.70833, y: 0.18 },
                { perspective: 'batter', x: 0.70833, y: 0.18 },
                { perspective: 'batter', x: 0.694167, y: 0.1 },
                { perspective: 'batter', x: 0, y: 0 },
                { perspective: 'batter', x: -0.694167, y: 0.1 },
                { perspective: 'batter', x: -0.70833, y: 0.18 },
            ],
            transform: [{ type: 'filter', expr: 'perspective === datum.perspective' }],
        },
    ],
    marks: [
        {
            type: 'rule',
            interactive: false,
            encode: {
                enter: {
                    x: { value: -rulebookZone.width / 2 / 3, scale: 'x' },
                    y: { value: rulebookZone.y, scale: 'y' },
                    y2: { value: rulebookZone.y2, scale: 'y' },
                    stroke: { value: '#000' },
                    strokeOpacity: { value: 0.20098039215686272 },
                    strokeWidth: { value: 1.25 },
                    strokeDash: { value: [6, 5] },
                },
                update: {
                    strokeOpacity: {
                        signal: "zoneType == '9-box' ? 0.20098039215686272 : 0",
                    },
                },
            },
        },
        {
            type: 'rule',
            interactive: false,
            encode: {
                enter: {
                    x: { value: rulebookZone.width / 2 / 3, scale: 'x' },
                    y: { value: rulebookZone.y, scale: 'y' },
                    y2: { value: rulebookZone.y2, scale: 'y' },
                    stroke: { value: '#000' },
                    strokeOpacity: { value: 0.20098039215686272 },
                    strokeWidth: { value: 1.25 },
                    strokeDash: { value: [6, 5] },
                },
                update: {
                    strokeOpacity: {
                        signal: "zoneType == '9-box' ? 0.20098039215686272 : 0",
                    },
                },
            },
        },
        {
            type: 'rule',
            interactive: false,
            encode: {
                enter: {
                    x: { value: -rulebookZone.width / 2, scale: 'x' },
                    x2: { value: rulebookZone.width / 2, scale: 'x' },
                    y: { value: rulebookZone.y - (rulebookZone.y - rulebookZone.y2) / 3, scale: 'y' },
                    stroke: { value: '#000' },
                    strokeOpacity: { value: 0.20098039215686272 },
                    strokeWidth: { value: 1.25 },
                    strokeDash: { value: [6, 5] },
                },
                update: {
                    strokeOpacity: {
                        signal: "zoneType == '9-box' ? 0.20098039215686272 : 0",
                    },
                },
            },
        },
        {
            type: 'rule',
            interactive: false,
            encode: {
                enter: {
                    x: { value: -rulebookZone.width / 2, scale: 'x' },
                    x2: { value: rulebookZone.width / 2, scale: 'x' },
                    y: { value: rulebookZone.y - 2 * ((rulebookZone.y - rulebookZone.y2) / 3), scale: 'y' },
                    stroke: { value: '#000' },
                    strokeOpacity: { value: 0.20098039215686272 },
                    strokeWidth: { value: 1.25 },
                    strokeDash: { value: [6, 5] },
                },
                update: {
                    strokeOpacity: {
                        signal: "zoneType == '9-box' ? 0.20098039215686272 : 0",
                    },
                },
            },
        },
        {
            type: 'line',
            interactive: false,
            from: { data: 'homePlateData' },
            encode: {
                enter: {
                    x: { scale: 'x', field: 'x' },
                    y: { scale: 'y', field: 'y' },
                    fill: { value: 'white' },
                    stroke: { value: '#CCCCCC' },
                    strokeWidth: { value: 1.25 },
                    strokeCap: { value: 'square' },
                },
                update: { fillOpacity: { value: 1 }, strokeOpacity: { value: 1 } },
            },
        },
        {
            type: 'rect',
            interactive: false,
            encode: {
                enter: {
                    x: { value: -rulebookZone.width / 2, scale: 'x' },
                    x2: { value: rulebookZone.width / 2, scale: 'x' },
                    y: { value: rulebookZone.y, scale: 'y' },
                    y2: { value: rulebookZone.y2, scale: 'y' },
                    fill: { value: '#000' },
                    fillOpacity: { value: 0.04901960784313722 },
                    stroke: { value: '#000' },
                    strokeOpacity: { value: 0.20098039215686272 },
                    strokeWidth: { value: 1.25 },
                    strokeDash: { value: [6, 5] },
                },
                update: {
                    strokeOpacity: {
                        signal: "zoneType != 'none' ? 0.20098039215686272 : 0",
                    },
                    fillOpacity: {
                        signal: "zoneType != 'percentile-box' ? 0.04901960784313722 : 0",
                    },
                },
            },
        },
        {
            type: 'path',
            from: { data: 'contextContour' },
            encode: {
                enter: {
                    opacity: { value: 0.75 },
                    stroke: { scale: 'contourColor', field: 'value' },
                    fill: { scale: 'contourColor', field: 'value' },
                    strokeWidth: { value: 1 },
                    strokeOpacity: { value: 0.3 },
                    fillOpacity: { value: 0.2 },
                },
            },
            transform: [{ type: 'geopath', field: 'datum' }],
        },
        {
            name: 'contextMarks',
            type: 'symbol',
            from: { data: 'contextData' },
            encode: {
                update: {
                    x: { scale: 'x', field: 'plateSide' },
                    y: { scale: 'y', field: 'plateHeight' },
                    size: { value: 10 },
                    fillOpacity: { value: 0.3 },
                    fill: { scale: 'pitchColor', field: 'pitchType' },
                },
            },
        },
        {
            type: 'group',
            from: {
                facet: {
                    data: 'ellipsesData',
                    name: 'ellipsesDataFacet',
                    groupby: 'percentile',
                },
            },
            marks: [
                {
                    type: 'line',
                    from: { data: 'ellipsesDataFacet' },
                    interactive: false,
                    encode: {
                        enter: {
                            x: { scale: 'x', field: 'x' },
                            y: { scale: 'y', field: 'y' },
                            fill: { value: '#000' },
                            stroke: { value: '#000' },
                            strokeDash: { value: [3, 2] },
                            strokeWidth: { value: 1 },
                        },
                        update: {
                            strokeOpacity: {
                                signal: "zoneType == 'percentile-box' ? 0.20098039215686272 : 0",
                            },
                            fillOpacity: {
                                signal: "zoneType == 'percentile-box' ? 0.0663235294117647 : 0",
                            },
                        },
                    },
                },
            ],
        },
        {
            name: 'hiddenMarks',
            type: 'symbol',
            from: { data: 'hiddenData' },
            encode: {
                enter: {
                    x: { scale: 'x', field: 'plateSide' },
                    y: { scale: 'y', field: 'plateHeight' },
                    stroke: { value: '#000000' },
                    strokeWidth: { value: 1 },
                    strokeOpacity: { value: 0.1 },
                    strokeDash: { value: [2, 2] },
                },
                update: { size: { signal: 'pitchSize' } },
            },
        },
        {
            type: 'group',
            from: { data: 'visibleData' },
            encode: {
                update: {
                    zindex: {
                        signal: 'currentId && currentId == datum.id ? 2 : hoveredId && hoveredId == datum.id ? 1 : 0',
                    },
                },
            },
            signals: [
                {
                    name: 'isSelectable',
                    update: 'selectable && isArray(parent.videos) && length(parent.videos) > 0',
                },
            ],
            marks: [
                {
                    type: 'symbol',
                    interactive: false,
                    encode: {
                        update: {
                            x: { scale: 'x', field: { parent: 'plateSide' } },
                            y: { scale: 'y', field: { parent: 'plateHeight' } },
                            size: { signal: 'pow((sqrt(pitchSize / PI) + 3), 2) * PI' },
                            stroke: { scale: 'pitchColor', field: { parent: 'pitchType' } },
                            strokeWidth: {
                                signal: 'min(max(1, pow((sqrt(pitchSize / PI) + (currentId ? 5 : 3)), 2) * PI / 96), 2)',
                            },
                            strokeOpacity: { signal: 'currentId ? 0 : 1' },
                            fill: {
                                signal: `currentId ? (currentId === parent.id ? scale('pitchColor', parent.pitchType) : 'transparent') : 'white'`,
                            },
                            fillOpacity: {
                                signal: '!currentId ? 1 : hoveredId && hoveredId == parent.id ? 0.5 : 0.25',
                            },
                            opacity: {
                                signal: '(currentId && parent.id == currentId) || (hoveredId && hoveredId == parent.id) ? 1 : 0',
                            },
                        },
                    },
                },
                {
                    name: 'visibleMarks',
                    type: 'symbol',
                    encode: {
                        enter: {
                            x: { scale: 'x', field: { parent: 'plateSide' } },
                            y: { scale: 'y', field: { parent: 'plateHeight' } },
                            fill: { scale: 'pitchColor', field: { parent: 'pitchType' } },
                        },
                        update: {
                            fillOpacity: {
                                signal: '((currentId && currentId === parent.id) || (hoveredId && hoveredId == parent.id)) ? 1 : 0.5',
                            },
                            size: {
                                signal: 'pow((sqrt(pitchSize / PI) + (hoveredId && hoveredId == parent.id ? 3 : 0)), 2) * PI',
                            },
                            tooltip: { signal: 'parent' },
                            cursor: {
                                signal: "isSelectable ? 'pointer' : 'default'",
                            },
                        },
                    },
                },
                {
                    type: 'text',
                    interactive: false,
                    encode: {
                        enter: {
                            x: { scale: 'x', field: { parent: 'plateSide' } },
                            y: { scale: 'y', field: { parent: 'plateHeight' } },
                            fill: { value: '#ffffff' },
                            text: { field: { parent: 'atbatPitchNumber' } },
                            align: { value: 'center' },
                            baseline: { value: 'middle' },
                        },
                        update: {
                            fontSize: {
                                signal: 'round(sqrt(pitchSize * PI) / 2.5) * (hoveredId && hoveredId == parent.id ? 1.5 : 1)',
                            },
                            opacity: { signal: 'showPitchNumbers ? 1 : 0' },
                        },
                    },
                },
            ],
        },
    ],
    scales: [
        {
            name: 'x',
            type: 'linear',
            domain: [{ signal: 'minX' }, { signal: 'maxX' }],
            range: 'width',
            clamp: true,
            reverse: { signal: "perspective === 'batter'" },
        },
        {
            name: 'y',
            type: 'linear',
            domain: [{ signal: 'minY' }, { signal: 'maxY' }],
            range: 'height',
            zero: false,
            clamp: true,
        },
        {
            name: 'pitchColor',
            type: 'ordinal',
            domain: map(playerStatsPitchTypes, (datum) => getPitchTypeCode(datum)),
            range: map(playerStatsPitchTypes, (datum) => getPitchTypeColor(datum)),
        },
        {
            name: 'contourColor',
            type: 'linear',
            zero: true,
            domain: { data: 'contextContour', field: 'value' },
            range: ['#ddd', { signal: " scale('pitchColor', pitchType)" }],
        },
    ],
};
