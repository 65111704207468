import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import dayjs from 'dayjs';
import { take } from 'rxjs/operators';
import type { View } from 'vega';

import { StlcI18nModule } from '@stlc/i18n/core';
import { assign, find, forEach, map } from '@stlc/lodash';
import { getStatSelectOption } from '@stlc/lookup/stat';
import { PlayerGamePitchFragment } from '@stlc/player-charts/data-access';
import { StlcSelectOption, StlcStat } from '@stlc/shared';
import { UiVegaChartComponent } from '@stlc/ui/vega';
import { UserService } from '@stlc/user';

import { gamePitches, strikeZoneSpec } from '../../specs';
import { PlayerChartsUiBattingTooltipComponent } from '../batting-tooltip';

@Component({
    selector: 'player-charts-ui-games-batting-pitch-charts',
    templateUrl: './games-batting-pitch-charts.component.html',
    styleUrls: ['./games-batting-pitch-charts.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        PlayerChartsUiBattingTooltipComponent,
        StlcI18nModule,
        UiVegaChartComponent,
    ],
})
export class PlayerChartsUiGamesBattingPitchChartsComponent implements OnInit, OnChanges {
    @Input()
    // pitches: PlayerGamePitchFragment[] = [];
    set pitches(values: PlayerGamePitchFragment) {
        this.data = map(values, (pitch, index) => assign(pitch, { pitchNumber: index + 1 }));
    }

    @Input()
    filteredIds: string[] | null = null;

    @Input()
    enableBrushing = false;

    charts = {
        gamePitches: {
            spec: gamePitches,
            signals: {
                yAxis: getStatSelectOption(StlcStat.ReleaseSpeed),
                showYAxisTitle: true,
                //filteredIds: null,
                pitchTypes: null,
            },
        },
        strikeZone: {
            spec: strikeZoneSpec,
            signals: {
                //filteredIds: null,
                pitchTypes: null,
            },
        },
    };

    readonly perspectiveOptions: StlcSelectOption[] = [
        {
            text: "Pitcher's Perspective",
            textKey: 'chart:pitcherPerspective_label',
            value: 'pitcher',
        },
        {
            text: "Batter's Perspective",
            textKey: 'chart:batterPerspective_label',
            value: 'batter',
        },
    ];
    selectedPerspective?: StlcSelectOption;

    perspectivePreferenceKey = 'player-charts:games:batting:perspective';

    data: PlayerGamePitchFragment[] = [];
    gamePitchesView?: View;
    strikeZoneView?: View;

    private readonly userService = inject(UserService);

    ngOnInit(): void {
        this.userService
            .getPreferenceValue$(this.perspectivePreferenceKey)
            .pipe(take(1))
            .subscribe((perspective?: string) => {
                this.selectedPerspective = find(this.perspectiveOptions, { value: perspective ?? 'batter' });
                this.updatePerspective();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['filteredIds']) {
            forEach([this.gamePitchesView, this.strikeZoneView], (view) => {
                if (view) {
                    view.signal('filteredIds', this.filteredIds).runAsync();
                }
            });
        }
    }

    gamePitchesRendered(view: View): void {
        this.gamePitchesView = view;
        this.gamePitchesView.signal('filteredIds', this.filteredIds).runAsync();
    }

    strikeZoneRendered(view: View): void {
        this.strikeZoneView = view;
        this.strikeZoneView.signal('filteredIds', this.filteredIds).runAsync();
        this.updatePerspective();
    }

    changePerspective(change: MatSelectChange): void {
        this.selectedPerspective = change.value;
        this.updatePerspective();
        this.userService.updatePreference(this.perspectivePreferenceKey, this.selectedPerspective?.value);
    }

    private updatePerspective() {
        if (this.strikeZoneView && this.selectedPerspective) {
            this.strikeZoneView.signal('perspective', this.selectedPerspective.value).runAsync();
        }
    }
}
