<ui-vega-chart
    [spec]="spec"
    [data]="data"
    [signals]="chartSignals"
    [protectedImageSignals]="protectedImageSignals"
    [tooltipTemplate]="tooltipTemplate"
    [tooltipXOffset]="15"
></ui-vega-chart>

<ng-template #tooltipTemplate let-item="item">
    <div class="flex flex-col text-xs max-w-lg w-36 whitespace-nowrap" *ngIf="item">
        <div class="flex flex-col mb-1">
            <div class="text-center">
                <span class="font-bold"
                    >{{ item.game?.gameDate | date: 'mediumDate' }}<br />
                    <span *ngIf="item.batter">{{ !item.pitcher ? 'vs ' : '' }}{{ item.batter | stlcPlayerName }}</span>
                    <span *ngIf="item.pitcher">vs {{ item.pitcher | stlcPlayerName }}</span>
                    <br
                /></span>
                {{ item.preBalls }} - {{ item.preStrikes }}, {{ 'game:out' | i18next: { count: item.preOuts } }}
            </div>
        </div>
        <div class="text-center">
            {{ item.pitchType | stlcI18nPitchType: 'legacy'
            }}<span *ngIf="item.releaseSpeed"
                >,
                {{ item.releaseSpeed | stlcNumberToFixed: { digits: 0, defaultText: '-', appendText: ' MPH' } }}</span
            >
        </div>
        <div class="text-center my-1">{{ item | stlcI18nGameEvent }}</div>
        <ng-container *ngIf="item.launchVerticalAngle || item.launchDistance || item.sprayAngle || item.sprayDistance">
            <mat-divider class="mb-1" style="position: unset"></mat-divider>
            <div class="flex" *ngIf="item.launchVerticalAngle || item.launchDistance">
                <div class="flex-1">
                    {{ item.launchVerticalAngle | stlcNumberToFixed: { digits: 1, appendText: '˚ LA' } }}
                </div>
                <div class="flex-1 text-right">
                    {{ item.launchInitialSpeed | stlcNumberToFixed: { digits: 0, appendText: ' MPH' } }}
                </div>
            </div>
            <div class="flex" *ngIf="item.sprayAngle || item.sprayDistance">
                <div class="flex-1">{{ item.sprayAngle | stlcNumberToFixed: { digits: 1, appendText: '˚ HA' } }}</div>
                <div class="flex-1 text-right">
                    {{ item.sprayDistance | stlcNumberToFixed: { digits: 0, appendText: "'" } }}
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
