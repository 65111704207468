import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import type { Spec } from 'vega';

import { StlcNumberToFixedPipe } from '@stlc/angular/pipes/number-to-fixed';
import { StlcPlayerNamePipe } from '@stlc/angular/pipes/player-name';
import { StlcI18nModule } from '@stlc/i18n/core';
import { UiVegaChartComponent, UiVegaProtectedImageSignals } from '@stlc/ui/vega';

import { defaultHeight, defaultWidth, StlcStrikeZoneImageOverlaySpec } from '../../specs';

@Component({
    selector: 'player-charts-ui-strike-zone-image-overlay-chart',
    templateUrl: './strike-zone-image-overlay-chart.component.html',
    styleUrls: ['./strike-zone-image-overlay-chart.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        StlcI18nModule,
        StlcNumberToFixedPipe,
        StlcPlayerNamePipe,
        UiVegaChartComponent,
    ],
})
export class StrikeZoneImageOverlayChartComponent implements OnChanges {
    @Input()
    type?: 'pitchClass' | 'pitchType';

    @Input()
    data: any;

    @Input()
    image?: string;

    @Input()
    signals: any;

    spec?: Spec;

    chartSignals: any;
    protectedImageSignals?: UiVegaProtectedImageSignals;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['type'] && this.type) {
            this.spec = new StlcStrikeZoneImageOverlaySpec(this.type).getSpec();
        }

        if (changes['signals']) {
            this.chartSignals = { ...this.signals };
        }

        if (changes['image']) {
            this.protectedImageSignals = {
                image: {
                    url: this.image,
                    width: defaultWidth,
                    height: defaultHeight,
                },
            };
        }
    }
}
