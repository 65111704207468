import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';

import { StlcFeetAndInchesPipe } from '@stlc/angular/pipes/feet-and-inches';
import { StlcNumberToFixedPipe } from '@stlc/angular/pipes/number-to-fixed';
import { StlcNumberToOrdinalPipe } from '@stlc/angular/pipes/number-to-ordinal';
import { StlcPlayerNamePipe } from '@stlc/angular/pipes/player-name';
import { StlcI18nModule } from '@stlc/i18n/core';
import { PlayerGamePitchFragment } from '@stlc/player-charts/data-access';

@Component({
    selector: 'player-charts-ui-batting-tooltip',
    templateUrl: './batting-tooltip.component.html',
    styleUrls: ['./batting-tooltip.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        StlcFeetAndInchesPipe,
        StlcI18nModule,
        StlcNumberToFixedPipe,
        StlcNumberToOrdinalPipe,
        StlcPlayerNamePipe,
    ],
})
export class PlayerChartsUiBattingTooltipComponent {
    @Input()
    pitch?: PlayerGamePitchFragment;
}
