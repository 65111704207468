import { CommonModule } from '@angular/common';
import { Component, computed, EventEmitter, Input, Output, signal, TemplateRef } from '@angular/core';
import { Spec } from 'vega';

import { Pitch } from '@stlc/game/shared';
import { UiVegaChartComponent } from '@stlc/ui/vega';

import { strikeZoneSpec } from '../../specs/strike-zone.vega';
import { PlayerChartsUiPitchingTooltipComponent } from '../pitching-tooltip';

@Component({
    selector: 'player-charts-ui-batter-bats-strike-zones',
    standalone: true,
    imports: [CommonModule, UiVegaChartComponent, PlayerChartsUiPitchingTooltipComponent],
    templateUrl: './batter-bats-strike-zones.component.html',
    styleUrls: ['./batter-bats-strike-zones.component.scss'],
})
export class BatterBatsStrikeZonesComponent {
    spec: Spec = strikeZoneSpec;

    @Input()
    header: string;

    @Input()
    batterBats: 'R' | 'L' | null;

    pitchesSignal = signal<Pitch[]>([]);
    @Input()
    set pitches(values: Pitch[]) {
        this.pitchesSignal.set(values);
    }

    @Input()
    signals = { perspective: 'pitcher' };

    signalListeners = {
        selectedId: (name: string, value: string) => {
            this.onWatchVideo(value);
        },
    };

    @Input()
    rhbCaption: TemplateRef<any>;

    @Input()
    lhbCaption: TemplateRef<any>;

    @Output()
    videoPitchIds = new EventEmitter<{ selectedPitchId?: string; pitches: Pitch[] }>();

    rhbPitches = computed(() => {
        const pitches = this.pitchesSignal();
        if (pitches) {
            return pitches.filter((pitch) => ['R', 'Right'].includes(pitch.batterBats));
        }
        return [];
    });

    lhbPitches = computed(() => {
        const pitches = this.pitchesSignal();
        if (pitches) {
            return pitches.filter((pitch) => ['L', 'Left'].includes(pitch.batterBats));
        }
        return [];
    });

    onWatchVideo(selectedPitchId: string): void {
        this.videoPitchIds.emit({ selectedPitchId, pitches: this.pitchesSignal() });
    }
}
