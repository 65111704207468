<div class="flex flex-wrap justify-center items-start gap-4">
    <div class="flex flex-col items-center space-y-2">
        <div class="text-gray-400 text-sm text-center">
            {{ 'chart:contactPosition_header' | i18next }}
        </div>
        <div class="flex flex-wrap justify-center items-center gap-4 p-4">
            <div class="flex-1 flex flex-col items-center">
                <ui-vega-chart
                    *ngIf="charts.strikeZone as config"
                    [spec]="config.spec"
                    [data]="data"
                    [signals]="config.signals"
                    [enableBrushing]="enableBrushing"
                    [tooltipTemplate]="tooltipTemplate"
                    (chartRendered)="strikeZoneRendered($event)"
                ></ui-vega-chart>
                <div class="density-4">
                    <mat-form-field class="stlc-mat-form-field-unstyled text-center" subscriptSizing="dynamic">
                        <mat-select
                            (selectionChange)="changePerspective($event)"
                            [value]="selectedPerspective"
                            panelClass="max-h-screen-80"
                        >
                            <mat-select-trigger>
                                <span
                                    *ngIf="selectedPerspective"
                                    class="text-gray-400 text-sm tracking-[0.0071428571rem] font-bold"
                                    [innerHTML]="
                                        selectedPerspective.textKey
                                            ? (selectedPerspective.textKey
                                              | i18next : { defaultValue: selectedPerspective.text })
                                            : selectedPerspective.text
                                    "
                                ></span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let datum of perspectiveOptions" [value]="datum">
                                <span
                                    [innerHTML]="
                                        datum.textKey
                                            ? (datum.textKey | i18next : { defaultValue: datum.text })
                                            : datum.text
                                    "
                                ></span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-1 flex flex-col items-center">
                <ui-vega-chart
                    *ngIf="charts.contact as config"
                    [spec]="config.spec"
                    [data]="data"
                    [signals]="config.signals"
                    [enableBrushing]="enableBrushing"
                    [tooltipTemplate]="tooltipTemplate"
                    (chartRendered)="contactRendered($event)"
                ></ui-vega-chart>
                <div class="flex text-gray-400 leading-10 text-sm">
                    {{ 'chart:overheadView_label' | i18next }}
                </div>
            </div>
        </div>
    </div>
    <div class="flex-1 flex flex-col items-center space-y-2">
        <div class="text-gray-400 text-sm text-center">
            {{ 'chart:sprayChart_title' | i18next : { defaultValue: 'Spray Chart' } }}
        </div>
        <ui-vega-chart
            *ngIf="charts.spray as config"
            [spec]="config.spec"
            [data]="data"
            [signals]="config.signals"
            [enableBrushing]="enableBrushing"
            [tooltipTemplate]="tooltipTemplate"
            (chartRendered)="sprayChartRendered($event)"
        />
        <div class="flex justify-center space-x-4 pt-4">
            <mat-checkbox color="gray" (change)="toggleShowSprayChartLines($event)" [checked]="showLines">
                <span class="text-gray-400 font-bold text-sm">{{ 'chart:showLines_label' | i18next }}</span>
            </mat-checkbox>
            <mat-checkbox color="gray" (change)="toggleShowSprayChartCircles($event)" [checked]="showCircles">
                <span class="text-gray-400 font-bold text-sm">{{ 'chart:showCircles_label' | i18next }}</span>
            </mat-checkbox>
        </div>
    </div>
</div>

<ng-template #tooltipTemplate let-item="item">
    <player-charts-ui-batting-tooltip [pitch]="item"></player-charts-ui-batting-tooltip>
</ng-template>
