<div class="flex flex-col md:flex-row space-x-0 px-2 space-y-4 md:space-x-2 md:px-4 md:space-y-0">
    <div class="md:flex-1">
        <ui-vega-chart
            *ngIf="charts.gamePitches as config"
            renderer="canvas"
            [spec]="config.spec"
            [data]="data"
            [signals]="config.signals"
            [enableBrushing]="enableBrushing"
            [tooltipTemplate]="tooltipTemplate"
            (chartRendered)="gamePitchesRendered($event)"
        ></ui-vega-chart>
    </div>
    <div class="flex flex-col justify-center items-center">
        <ui-vega-chart
            *ngIf="charts.strikeZone as config"
            renderer="canvas"
            [spec]="config.spec"
            [data]="data"
            [signals]="config.signals"
            [enableBrushing]="enableBrushing"
            [tooltipTemplate]="tooltipTemplate"
            (chartRendered)="strikeZoneRendered($event)"
        />
        <div class="density-4">
            <mat-form-field class="stlc-mat-form-field-unstyled text-center" subscriptSizing="dynamic">
                <mat-select
                    (selectionChange)="changePerspective($event)"
                    [value]="selectedPerspective"
                    panelClass="max-h-screen-80"
                >
                    <mat-select-trigger>
                        <span
                            *ngIf="selectedPerspective"
                            class="text-gray-400 text-sm tracking-[0.0071428571rem] font-bold"
                            [innerHTML]="
                                selectedPerspective.textKey
                                    ? (selectedPerspective.textKey
                                      | i18next : { defaultValue: selectedPerspective.text })
                                    : selectedPerspective.text
                            "
                        ></span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let datum of perspectiveOptions" [value]="datum">
                        <span
                            [innerHTML]="
                                datum.textKey ? (datum.textKey | i18next : { defaultValue: datum.text }) : datum.text
                            "
                        ></span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>

<ng-template #tooltipTemplate let-item="item">
    <player-charts-ui-batting-tooltip [pitch]="item"></player-charts-ui-batting-tooltip>
</ng-template>
