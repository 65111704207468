import { Overlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { I18NEXT_SERVICE } from 'angular-i18next';
import { filter, map, Subject, take, takeUntil, withLatestFrom } from 'rxjs';
import type { Spec } from 'vega';

import { GoogleAnalyticsService } from '@stlc/angular/services';
import { StlcI18nModule } from '@stlc/i18n/core';
import { chain, find, isEmpty, isNil, map as _map, size, some } from '@stlc/lodash';
import {
    PitchTypeSelectOptionFragment,
    PlayerGameFragment,
    PlayerGamePitchFragment,
    PlayerStatsHandedness,
    StringSelectOptionFragment,
} from '@stlc/player-charts/data-access';
import { PlayerStatsGamePitchesCardComponent } from '@stlc/player-stats/ui';
import { StlcSelectOption } from '@stlc/shared';
import { UiChipListComponent, UiChipSelectComponent } from '@stlc/ui';
import { UiDialogService } from '@stlc/ui/dialog';
import { UiAppService } from '@stlc/ui/services';
import { UiVegaBrushingService } from '@stlc/ui/vega';
import { UiVideoDialogComponent } from '@stlc/ui/video';

import { videoStrikeZoneSpec } from '../../specs';
import { PlayerChartsUiGamesBattingContactChartsComponent } from '../games-batting-contact-charts';
import { PlayerChartsUiGamesBattingPitchChartsComponent } from '../games-batting-pitch-charts';
import { PlayerChartsUiGamesCountFilterComponent } from '../games-count-filter';

import { BattingChartView, PlayerChartsUiGamesBattingService } from './games-batting.service';

@Component({
    selector: 'player-charts-ui-games-batting-container',
    templateUrl: './games-batting-container.component.html',
    styleUrls: ['./games-batting-container.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        PlayerChartsUiGamesBattingContactChartsComponent,
        PlayerChartsUiGamesBattingPitchChartsComponent,
        PlayerChartsUiGamesCountFilterComponent,
        PlayerStatsGamePitchesCardComponent,
        StlcI18nModule,
        UiChipListComponent,
        UiChipSelectComponent,
    ],
    providers: [UiVegaBrushingService, PlayerChartsUiGamesBattingService],
})
export class PlayerChartsUiGamesBattingContainerComponent implements OnInit, OnChanges {
    @Input()
    games: PlayerGameFragment[];

    @Input()
    loading = false;

    videoStrikeZoneSpec: Spec = videoStrikeZoneSpec;
    chartBrushable = false;
    chartSelectable = true;

    private readonly destroy = new Subject<void>();

    readonly appService = inject(UiAppService);
    readonly brushingService = inject(UiVegaBrushingService);
    readonly service = inject(PlayerChartsUiGamesBattingService);

    private readonly router = inject(Router);
    private readonly route = inject(ActivatedRoute);
    private readonly gaService = inject(GoogleAnalyticsService);
    private readonly dialog = inject(UiDialogService);
    private readonly i18next = inject(I18NEXT_SERVICE);

    ngOnInit() {
        this.brushingService.selectedId$
            .pipe(
                takeUntil(this.destroy),
                filter((selectedId) => !isNil(selectedId)),
                withLatestFrom(this.service.pitches$),
                map(([selectedId, pitches]) => find(pitches, ({ id }) => id === selectedId)),
                filter((pitch) => !isEmpty(pitch?.videos))
            )
            .subscribe((pitch) => {
                this.openVideoDialog(pitch);
            });

        this.brushingService.selectedIds$.pipe(takeUntil(this.destroy)).subscribe((ids) => {
            if (ids && ids.length > 0) {
                this.toggleChartBrushable();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['games']) {
            this.service.games = this.games;
            this.brushingService.clearSelected();
        }
    }

    get ChartView() {
        return BattingChartView;
    }

    clearChartSelected(_event?: MouseEvent): void {
        this.brushingService.clearSelected();
        this.chartBrushable = false;
        this.chartSelectable = true;
        this.brushingService.signal('selectable', this.chartSelectable);
        this.brushingService.signal('brushable', this.chartBrushable);
    }

    toggleChartBrushable(event?: MouseEvent): void {
        this.chartBrushable = !this.chartBrushable;
        this.chartSelectable = !this.chartBrushable;
        setTimeout(() => {
            this.brushingService.signal('selectable', this.chartSelectable);
            this.brushingService.signal('brushable', this.chartBrushable);
        }, 250);
        if (event) {
            this.gaService.event('toggle_brushing', {
                value: this.chartBrushable,
            });
        }
    }

    viewChange({ value: view }: { value: BattingChartView | undefined }): void {
        if (view === BattingChartView.Pitch) {
            view = undefined;
        }

        this.updateQueryParams({ view });
    }

    pitchTypesChange(values: PitchTypeSelectOptionFragment[]): void {
        this.updateQueryParams({ pitchType: _map(values, 'pitchType') });
    }

    pitcherThrowsChange(selected?: StlcSelectOption<PlayerStatsHandedness>): void {
        this.updateQueryParams({ pitcherThrows: selected?.value });
    }

    countsChange(selected?: StringSelectOptionFragment[]): void {
        this.updateQueryParams({ count: selected });
    }

    pitchResultsChange(selected?: StringSelectOptionFragment[]): void {
        if (!isEmpty(selected) && some(selected, (datum) => isNil(datum.value))) {
            selected = undefined;
        }

        this.updateQueryParams({ pitchResult: selected ? _map(selected, 'value') : undefined });
    }

    basesOccupiedChange(selected?: StlcSelectOption<string[] | null>[]): void {
        this.updateQueryParams({ basesOccupied: _map(selected, 'value') });
    }

    otherFiltersChange(selected?: StlcSelectOption<string>[]): void {
        this.updateQueryParams({ other: _map(selected, 'value') });
    }

    resetFilters() {
        this.updateQueryParams({
            pitchType: undefined,
            pitcherThrows: undefined,
            count: undefined,
            pitchResult: undefined,
            basesOccupied: undefined,
            other: undefined,
        });
    }

    async updateQueryParams(queryParams: Params = {}): Promise<void> {
        const success = await this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
            replaceUrl: true,
        });

        if (success) {
            this.brushingService.clearSelected();
        }
    }

    openVideoDialog(pitch?: PlayerGamePitchFragment): void {
        if (this.service.videoEvents$) {
            this.service.videoEvents$.pipe(take(1)).subscribe((videoEvents) => {
                if (isEmpty(videoEvents)) {
                    return;
                }
                this.gaService.event('watch_video', {
                    number_of_videos: size(videoEvents),
                    video_specified: !isNil(pitch?.id),
                });
                this.dialog.open(UiVideoDialogComponent, {
                    data: {
                        charts: [
                            {
                                name: 'Location',
                                spec: this.videoStrikeZoneSpec,
                            },
                        ],
                        selectedEvent: pitch?.id,
                        eventGroups: chain(videoEvents)
                            .groupBy('eventGroup')
                            .map((value) => value)
                            .valueOf(),
                        previousEventGroupLabel: `${this.i18next.t('common:previous')} PA`,
                        nextEventGroupLabel: `${this.i18next.t('common:next')} PA`,
                        selectedEventType: 'pitches',
                    },
                    panelClass: 'stlc-video-dialog',
                });
            });
        }
    }
}
