import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { StlcDegreeToClockFacePipe } from '@stlc/angular/pipes/degree-to-clock-face';
import { StlcFeetAndInchesPipe } from '@stlc/angular/pipes/feet-and-inches';
import { StlcNumberToFixedPipe } from '@stlc/angular/pipes/number-to-fixed';
import { StlcNumberToOrdinalPipe } from '@stlc/angular/pipes/number-to-ordinal';
import { StlcPlayerNamePipe } from '@stlc/angular/pipes/player-name';
import { StlcI18nModule } from '@stlc/i18n/core';
import { PlayerGamePitchFragment } from '@stlc/player-charts/data-access';

@Component({
    selector: 'player-charts-ui-pitching-tooltip',
    templateUrl: './pitching-tooltip.component.html',
    styleUrls: ['./pitching-tooltip.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        StlcDegreeToClockFacePipe,
        StlcFeetAndInchesPipe,
        StlcI18nModule,
        StlcNumberToFixedPipe,
        StlcNumberToOrdinalPipe,
        StlcPlayerNamePipe,
    ],
})
export class PlayerChartsUiPitchingTooltipComponent {
    @Input()
    pitch?: PlayerGamePitchFragment;

    @Input()
    showGamePitchNumber = true;

    @Input()
    showBatter: boolean;

    @Input()
    showPitcher: boolean;
}
