<div class="flex flex-col md:flex-row space-x-0 px-2 space-y-4 md:space-x-2 md:px-4 md:space-y-0">
    <div class="chart-container md:flex-1">
        <div class="flex justify-center">
            <ui-chip-select
                chipColor="none"
                [required]="true"
                [options]="service.statOptions"
                (selectedChange)="statChange($event)"
            >
            </ui-chip-select>
        </div>
        <ui-vega-chart
            *ngIf="charts.gamePitches as config"
            renderer="canvas"
            [spec]="config.spec"
            [data]="data"
            [signals]="config.signals"
            [enableBrushing]="enableBrushing"
            [tooltipTemplate]="tooltipTemplate"
            (chartRendered)="gamePitchesRendered($event)"
        ></ui-vega-chart>
    </div>
    <div *ngIf="selectedChart as chart" class="chart-container min-w-[280px]">
        <div class="flex justify-center">
            <ui-chip-select
                chipColor="none"
                [required]="true"
                [options]="chartOptions"
                (selectedChange)="chartChange($event)"
            >
            </ui-chip-select>
        </div>
        <div [ngSwitch]="chart.value" class="flex justify-center min-h-[360px]">
            <div class="flex flex-col items-center">
                <ng-container *ngSwitchCase="'pitchRelease'">
                    <ui-vega-chart
                        *ngIf="charts.pitchRelease as config"
                        renderer="canvas"
                        [spec]="config.spec"
                        [data]="data"
                        [enableBrushing]="enableBrushing"
                        [tooltipTemplate]="tooltipTemplate"
                        (chartRendered)="pitchReleaseRendered($event)"
                    ></ui-vega-chart>
                </ng-container>
                <ng-container *ngSwitchCase="'pitchMovement'">
                    <ui-vega-chart
                        *ngIf="charts.pitchMovement as config"
                        renderer="canvas"
                        [spec]="config.spec"
                        [data]="data"
                        [enableBrushing]="enableBrushing"
                        [tooltipTemplate]="tooltipTemplate"
                        (chartRendered)="pitchMovementRendered($event)"
                    ></ui-vega-chart>
                </ng-container>
                <ng-container *ngSwitchCase="'location'">
                    <ui-vega-chart
                        *ngIf="charts.strikeZone as config"
                        renderer="canvas"
                        [spec]="config.spec"
                        [data]="data"
                        [enableBrushing]="enableBrushing"
                        [tooltipTemplate]="tooltipTemplate"
                        (chartRendered)="strikeZoneRendered($event)"
                    ></ui-vega-chart>
                </ng-container>
                <div *ngSwitchDefault class="stlc-no-data-found">No chart found</div>
                <div class="density-4">
                    <mat-form-field class="stlc-mat-form-field-unstyled" subscriptSizing="dynamic">
                        <mat-select
                            (selectionChange)="changePerspective($event)"
                            [value]="selectedPerspective"
                            panelClass="max-h-screen-80"
                        >
                            <mat-select-trigger>
                                <span
                                    *ngIf="selectedPerspective"
                                    class="text-gray-400 text-sm tracking-[0.0071428571rem] font-bold"
                                    [innerHTML]="
                                        selectedPerspective.textKey
                                            ? (selectedPerspective.textKey
                                              | i18next : { defaultValue: selectedPerspective.text })
                                            : selectedPerspective.text
                                    "
                                ></span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let datum of perspectiveOptions" [value]="datum">
                                <span
                                    [innerHTML]="
                                        datum.textKey
                                            ? (datum.textKey | i18next : { defaultValue: datum.text })
                                            : datum.text
                                    "
                                ></span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #tooltipTemplate let-item="item">
    <player-charts-ui-pitching-tooltip [pitch]="item" showBatter="true"></player-charts-ui-pitching-tooltip>
</ng-template>
