<ui-chip-select
    [chipColor]="!selected || selected.length === 0 ? 'none' : 'primary'"
    multiple="true"
    prefix="Count"
    placeholder="Count"
    [disabled]="disabled"
    [options]="options"
    (selectedChange)="emitValueChange($event)"
    [disabled]="disabled"
></ui-chip-select>
