import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { chain, find, head } from '@stlc/lodash';
import {
    PlayerGameLogSummarySelectOption,
    PlayerGameLogSummarySelectOptionGroup,
} from '@stlc/player-charts/data-access';
import { UiResponsiveDropdownComponent } from '@stlc/ui';
import { UiAppService } from '@stlc/ui/services';

/**
 * @deprecated
 * Switch to use ui-responsive-dropdown directly
 */
@Component({
    selector: 'player-charts-ui-game-log-summary-dropdown',
    templateUrl: './game-log-summary-dropdown.component.html',
    styleUrls: ['./game-log-summary-dropdown.component.scss'],
    standalone: true,
    imports: [CommonModule, UiResponsiveDropdownComponent],
})
export class PlayerChartsUiGameLogSummaryDropdownComponent implements OnChanges {
    @Input()
    groups: PlayerGameLogSummarySelectOptionGroup[] = [];

    #selected?: PlayerGameLogSummarySelectOption;
    @Input()
    set selected(option: PlayerGameLogSummarySelectOption) {
        this.#selected = option;
        this.id = option.value;
        this.label = option.text;
    }

    get selected() {
        return this.#selected;
    }

    @Input()
    disabled = false;

    @Output()
    valueChange = new EventEmitter<PlayerGameLogSummarySelectOption>();

    id: string;
    label: string;
    #options: PlayerGameLogSummarySelectOption[] = [];

    readonly isMobile$ = inject(UiAppService).isMobile$;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['groups']) {
            this.#options = chain(this.groups).map('options').flatten().valueOf();
            this.selected = find(this.#options, { value: this.id }) ?? head(this.#options);
        }
    }

    valueChanged(values: PlayerGameLogSummarySelectOption[]): void {
        if (values.length === 1) {
            const option = find(this.#options, { value: values[0]?.value }) ?? head(this.#options);
            if (option === this.selected) {
                return;
            }

            this.valueChange.emit(option);
        }
    }
}
