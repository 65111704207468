import type { Spec } from 'vega';

import { map } from '@stlc/lodash';
import { pitchTypes } from '@stlc/lookup/legacy';

export const spraySpec: Spec = {
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    width: 350,
    height: 301,
    autosize: { type: 'none', contains: 'padding' },
    usermeta: { resize: false },
    background: 'transparent',
    config: {
        signals: [
            { name: 'backgroundColor' },
            { name: 'textColor' },
            { name: 'gridColor' },
            { name: 'labelColor' },
            { name: 'tickColor' },
            { name: 'titleColor' },
        ],
        axis: {
            gridColor: { signal: "gridColor || darkTheme ? '#dddddd' : '#dddddd'" },
            gridOpacity: 1,
            labelColor: {
                signal: "labelColor || darkTheme ? '#cccccc' : '#333333'",
            },
            labelFont: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            labelFontSize: 11,
            labelFontWeight: 'normal',
            tickColor: { signal: "tickColor || darkTheme ? '#999999' : '#999999'" },
            titleFont: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            titleFontSize: 16,
            titleFontWeight: 'normal',
            titleColor: {
                signal: "titleColor || darkTheme ? '#cccccc' : '#333333'",
            },
            titlePadding: 16,
        },
        text: {
            font: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            fill: { signal: "textColor || (darkTheme ? '#cccccc' : '#333333')" },
        },
        events: { defaults: { allow: ['wheel', 'touchstart'] } },
    },
    signals: [
        { name: 'width', value: 349 },
        { name: 'fieldDimensionsInFeet', value: { width: 500, height: 430 } },
        { name: 'height', update: '(344/400) * width' },
        { name: 'insufficientData', value: false },
        { name: 'showLines', value: true },
        { name: 'showCircles', value: true },
        { name: 'xAxisField', value: 'xCoordinate' },
        { name: 'yAxisField', value: 'yCoordinate' },
        { name: 'hitHardColor', value: '#dc2626' },
        { name: 'defaultColor', value: '#000000' },
        { name: 'inactiveColor', value: '#737373' },
        {
            name: 'lineWidth',
            update: 'min(width / fieldDimensionsInFeet.width * 3, 3)',
        },
        { name: 'filteredIds', value: null },
        { name: 'selectedIds', value: null },
        { name: 'hasSelected', update: '(selectedIds && length(selectedIds)) || (brushedIds && length(brushedIds))' },
        { name: 'colorField', value: 'pitchType' },
        {
            name: 'hovered',
            value: null,
            on: [
                {
                    events: '@circles:mouseover, @lines:mouseover',
                    update: '!brushed && group().datum ? group().datum.id : null',
                },
                { events: '@circles:mouseout, @lines:mouseout', update: 'null' },
            ],
        },
        { name: 'hoveredId', value: 'hovered' },
        { name: 'isInteractive', update: '!brushable && selectable' },
        { name: 'selectable', value: true },
        { name: 'brushable', value: false },
        {
            name: 'selectedId',
            value: null,
            on: [
                {
                    events: [
                        {
                            merge: [
                                {
                                    type: 'mousedown',
                                    markname: 'lines',
                                },
                                {
                                    type: 'touchstart',
                                    markname: 'lines',
                                },
                                {
                                    type: 'mousedown',
                                    markname: 'circles',
                                },
                                {
                                    type: 'touchstart',
                                    markname: 'circles',
                                },
                            ],
                        },
                    ],
                    update: 'selectable ? group().datum.id : null',
                    force: true,
                },
            ],
        },
        {
            name: 'brushStart',
            value: null,
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                        },
                        {
                            type: 'touchstart',
                            consume: true,
                        },
                    ],
                    update: 'brushable ? { x: clamp(x(), 0, width), y: clamp(y(), 0, height) } : null',
                    // force: true,
                },
            ],
        },
        {
            name: 'brushEnd',
            value: {},
            on: [
                {
                    events: 'mousedown, [mousedown, window:mouseup] > window:mousemove, touchstart, [touchstart, window:touchend] > window:touchmove',
                    update: 'brushable ? { x: clamp(x(), 0, width), y: clamp(y(), 0, height) } : {}',
                    // force: true,
                },
            ],
        },
        {
            name: 'brushed',
            value: false,
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                        },
                        {
                            type: 'touchstart',
                        },
                    ],
                    update: '!selectable && brushable ? true : false',
                },
                {
                    events: 'window:mouseup, window:touchend',
                    update: 'false',
                },
            ],
        },
        {
            name: 'brushX',
            update: `brushStart ? extent(invert('x', [brushStart.x, brushEnd.x])) : []`,
        },
        {
            name: 'brushY',
            update: `brushStart ? extent(invert('y', [brushStart.y, brushEnd.y])) : []`,
        },
        {
            name: 'brushOperation',
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                        },
                        {
                            type: 'touchstart',
                            consume: true,
                        },
                    ],
                    update: "event.shiftKey && !event.altKey ? 'add' : event.altKey && !event.shiftKey ? 'remove' : null",
                },
            ],
        },
        { name: 'brushedIds', value: null, react: true },
        { name: 'currentId', value: null },
    ],
    data: [
        {
            name: 'venueData',
            values: [
                { x: 0, y: 0 },
                { x: -239.11748, y: 239.1175 },
                { x: -242.57996, y: 252.399 },
                { x: -127.87918, y: 366.5628 },
                { x: -37.4496, y: 397.6625 },
                { x: 28.69823, y: 397.6625 },
                { x: 119.22502, y: 369.2821 },
                { x: 229.37439, y: 265.8184 },
                { x: 236.6582, y: 236.6582 },
                { x: 0, y: 0 },
            ],
        },
        { name: 'compareVenueData', values: [] },
        {
            name: 'baseLineData',
            values: [
                { x: 0, y: 0 },
                { x: 63.63961030678928, y: 63.63961030678927 },
                { x: 0, y: 127.375 },
                { x: -63.63961030678928, y: 63.63961030678927 },
                { x: 0, y: 0 },
            ],
        },
        {
            name: 'data',
            values: [],
            transform: [
                {
                    type: 'filter',
                    expr: '(isValid(datum.sprayDistance) || isValid(datum.hitLaunchDistance)) && (isValid(datum.hitLaunchBearing) || isValid(datum.sprayAngle))',
                },
                {
                    type: 'formula',
                    as: 'xCoordinate',
                    expr: "sin((datum.pitchResult == 'IP' ? clamp(datum.sprayAngle || datum.hitLaunchBearing, -45, 45) : (datum.sprayAngle || datum.hitLaunchBearing)) * PI / 180) * (datum.sprayDistance || datum.hitLaunchDistance)",
                },
                {
                    type: 'formula',
                    as: 'yCoordinate',
                    expr: "cos((datum.pitchResult == 'IP' ? clamp(datum.sprayAngle || datum.hitLaunchBearing, -45, 45) : (datum.sprayAngle || datum.hitLaunchBearing)) * PI / 180) * (datum.sprayDistance || datum.hitLaunchDistance)",
                },
            ],
        },
        {
            name: 'visibleData',
            source: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: '!filteredIds || (filteredIds && length(filteredIds) > 0 && indexof(filteredIds, datum.id) >= 0)',
                },
            ],
        },
        {
            name: 'hiddenData',
            source: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: 'filteredIds && (length(filteredIds) == 0 || indexof(filteredIds, datum.id) == -1)',
                },
            ],
        },
        {
            name: 'brushedData',
            source: 'visibleData',
            transform: [
                {
                    type: 'filter',
                    expr: [
                        // 'datum.plateSide >= brushX1 && datum.plateSide >= brushX2',
                        // 'datum.plateHeight <= brushY1 && datum.plateHeight >= brushY2',
                        'inrange(datum[xAxisField], brushX)',
                        'inrange(datum[yAxisField], brushY)',
                        // '!inrange(selectedIds, datum.id)',
                    ].join(' && '),
                },
            ],
        },
    ],
    scales: [
        { name: 'x', type: 'linear', domain: [-250, 250], range: 'width' },
        { name: 'y', type: 'linear', domain: [-5, 425], range: 'height' },
        {
            name: 'bipColor',
            type: 'ordinal',
            domain: [true, false],
            range: ['#852528', 'black'],
        },
        {
            name: 'bipFillOpacity',
            type: 'ordinal',
            domain: ['hit', 'out'],
            range: [1, 0],
        },
        {
            name: 'launchSpeedColor',
            type: 'linear',
            domain: [80, 100],
            range: ['#FFAAAA', '#FF0000'],
        },
        {
            name: 'launchSpeedWidth',
            type: 'linear',
            domain: [80, 100],
            range: [1, 5],
        },
        {
            name: 'pitchTypeColor',
            type: 'ordinal',
            domain: map(pitchTypes, 'name'),
            range: map(pitchTypes, 'color'),
        },
    ],
    marks: [
        {
            type: 'line',
            from: { data: 'venueData' },
            encode: {
                enter: {
                    x: { scale: 'x', field: 'x' },
                    y: { scale: 'y', field: 'y' },
                    fill: { value: 'white' },
                    stroke: { value: '#d0d0d0' },
                },
                update: {
                    strokeWidth: {
                        signal: 'min(width / fieldDimensionsInFeet.width * 3, 3)',
                    },
                },
            },
        },
        {
            type: 'line',
            from: { data: 'compareVenueData' },
            encode: {
                enter: {
                    x: { scale: 'x', field: 'x' },
                    y: { scale: 'y', field: 'y' },
                    stroke: { value: '#d0d0d0' },
                    strokeOpacity: { value: 0.5 },
                },
                update: {
                    strokeWidth: {
                        signal: 'min(width / fieldDimensionsInFeet.width * 3, 3)',
                    },
                    strokeDash: { signal: '[width / 500 * 8, width / 500 * 8]' },
                },
            },
        },
        {
            type: 'arc',
            encode: {
                enter: {
                    x: { scale: 'x', value: 0 },
                    y: { scale: 'y', value: 59 },
                    startAngle: { value: -1.271 },
                    endAngle: { value: 1.271 },
                    fill: { value: '#d0d0d0' },
                },
                update: {
                    outerRadius: {
                        scale: 'y',
                        signal: 'fieldDimensionsInFeet.height - 95',
                    },
                },
            },
        },
        {
            type: 'arc',
            encode: {
                enter: {
                    x: { scale: 'x', value: 0 },
                    startAngle: { value: -1.271 },
                    endAngle: { value: 1.271 },
                    fill: { value: 'white' },
                },
                update: {
                    y: {
                        scale: 'y',
                        value: 58,
                        offset: {
                            signal: 'min(width / fieldDimensionsInFeet.width * 3, 3)',
                        },
                    },
                    outerRadius: {
                        scale: 'y',
                        signal: 'fieldDimensionsInFeet.height - 95',
                    },
                },
            },
        },
        {
            type: 'arc',
            encode: {
                enter: {
                    x: { scale: 'x', value: 0 },
                    y: { scale: 'y', value: 59 },
                    outerRadius: { signal: 'width / fieldDimensionsInFeet.width * 9' },
                    startAngle: { value: 0 },
                    endAngle: { value: 6.283185307179586 },
                    strokeWidth: {
                        signal: 'min(width / fieldDimensionsInFeet.width * 3, 3)',
                    },
                    stroke: { value: '#d0d0d0' },
                    strokeOpacity: { value: 1 },
                },
            },
        },
        {
            type: 'line',
            from: { data: 'baseLineData' },
            encode: {
                enter: {
                    x: { scale: 'x', field: 'x' },
                    y: { scale: 'y', field: 'y' },
                    stroke: { value: '#d0d0d0' },
                    strokeCap: { value: 'square' },
                },
                update: {
                    strokeWidth: {
                        signal: 'min(width / fieldDimensionsInFeet.width * 3, 3)',
                    },
                },
            },
        },
        {
            type: 'rule',
            encode: {
                enter: {
                    x: { scale: 'x', value: 0 },
                    y: { scale: 'y', value: 0 },
                    x2: { scale: 'x', value: 200 },
                    y2: { scale: 'y', value: 200 },
                    stroke: { value: '#d0d0d0' },
                },
                update: {
                    strokeWidth: {
                        signal: 'min(width / fieldDimensionsInFeet.width * 3, 3)',
                    },
                },
            },
        },
        {
            type: 'rule',
            encode: {
                enter: {
                    x: { scale: 'x', value: 0 },
                    y: { scale: 'y', value: 0 },
                    x2: { scale: 'x', value: -200 },
                    y2: { scale: 'y', value: 200 },
                    stroke: { value: '#d0d0d0' },
                },
                update: {
                    strokeWidth: {
                        signal: 'min(width / fieldDimensionsInFeet.width * 3, 3)',
                    },
                },
            },
        },
        {
            name: 'hiddenCircles',
            from: { data: 'hiddenData' },
            type: 'symbol',
            encode: {
                update: {
                    x: { scale: 'x', field: { signal: 'xAxisField' } },
                    y: { scale: 'y', field: { signal: 'yAxisField' } },
                    size: {
                        signal: 'min(width / fieldDimensionsInFeet.width * 128, 128)',
                    },
                    stroke: { signal: 'defaultColor' },
                    strokeWidth: { value: 1 },
                    strokeOpacity: { value: 0.1 },
                    strokeDash: { value: [2, 2] },
                    zindex: {
                        signal: '(hoveredId && hoveredId == datum.id) || (selectedId && selectedId == datum.id) || (currentId && currentId == datum.id) ? 1 : 0',
                    },
                },
            },
        },
        {
            name: 'hiddenLines',
            from: { data: 'hiddenData' },
            interactive: false,
            type: 'rule',
            encode: {
                update: {
                    x: { scale: 'x', field: 'xCoordinate' },
                    y: { scale: 'y', field: 'yCoordinate' },
                    x2: { scale: 'x', value: 0 },
                    y2: { scale: 'y', value: 0 },
                    stroke: {
                        signal: "showLines ? datum.hitHard ? hitHardColor : defaultColor : ''",
                    },
                    strokeOpacity: { signal: '0.1' },
                    strokeDash: { signal: "datum.pitchResult == 'IP' ? [1,0] : [8,8]" },
                    strokeWidth: {
                        signal: 'min(width / fieldDimensionsInFeet.width * 1, 1)',
                    },
                    strokeCap: { value: 'round' },
                    zindex: {
                        signal: '(hoveredId && hoveredId == datum.id) || (selectedId && selectedId == datum.id) || (currentId && currentId == datum.id) ? 1 : 0',
                    },
                },
            },
        },
        {
            type: 'group',
            from: { data: 'visibleData' },
            encode: {
                update: {
                    zindex: {
                        signal: 'currentId && currentId == datum.id ? 2 : hoveredId && hoveredId == datum.id ? 1 : 0',
                    },
                },
            },
            signals: [
                {
                    name: 'isBrushedOrSelected',
                    update: '(brushedIds && indexof(brushedIds, parent.id) !== -1) || (selectedIds && indexof(selectedIds, parent.id) !== -1)',
                },
                { name: 'isHidden', update: '(brushedIds || selectedIds) && !isBrushedOrSelected' },
                {
                    name: 'isSelectable',
                    update: 'selectable && !isHidden && isArray(parent.videos) && length(parent.videos) > 0',
                },
            ],
            marks: [
                {
                    name: 'lines',
                    type: 'rule',
                    interactive: { signal: 'isInteractive && !isHidden' },
                    encode: {
                        update: {
                            x: { scale: 'x', signal: 'parent[xAxisField]' },
                            y: { scale: 'y', signal: 'parent[yAxisField]' },
                            x2: { scale: 'x', value: 0 },
                            y2: { scale: 'y', value: 0 },
                            stroke: {
                                scale: 'pitchTypeColor',
                                signal: "showLines ? (isHidden ? 'Unknown' : parent[colorField]) : null",
                            },
                            strokeOpacity: { signal: 'hoveredId && hoveredId == parent.id ? 1 : 0.5' },
                            strokeWidth: { signal: '(hoveredId == parent.id  ? 2 : 1) * lineWidth' },
                            strokeDash: { signal: "parent.pitchResult == 'IP' ? [1,0] : [8,8]" },
                            strokeCap: { value: 'round' },
                            cursor: { signal: "isSelectable ? 'pointer' : 'default'" },
                            tooltip: { signal: 'isInteractive && !isHidden ? parent : null' },
                        },
                    },
                },
                {
                    type: 'symbol',
                    interactive: false,
                    encode: {
                        enter: {
                            fill: { value: 'white' },
                            strokeOpacity: { value: 1 },
                            strokeWidth: { value: 2 },
                        },
                        update: {
                            x: { scale: 'x', signal: 'parent[xAxisField]' },
                            y: { scale: 'y', signal: 'parent[yAxisField]' },
                            size: {
                                signal: 'pow((sqrt(min(width / fieldDimensionsInFeet.width * 128, 128) / PI) + 3), 2) * PI',
                            },
                            stroke: { scale: 'pitchTypeColor', signal: 'parent[colorField]' },
                            opacity: {
                                signal: 'showCircles && ((currentId && parent.id == currentId) || (hoveredId && hoveredId == parent.id)) ? 1 : 0',
                            },
                        },
                    },
                },
                {
                    name: 'circles',
                    type: 'symbol',
                    interactive: { signal: 'isInteractive && !isHidden' },
                    encode: {
                        update: {
                            x: { scale: 'x', signal: 'parent[xAxisField]' },
                            y: { scale: 'y', signal: 'parent[yAxisField]' },
                            size: {
                                signal: "parent.pitchResult == 'IP' ? min(width / fieldDimensionsInFeet.width * 128, 128) : pow((sqrt(min(width / fieldDimensionsInFeet.width * 128, 128) / PI) - 1), 2) * PI",
                            },
                            fill: {
                                scale: 'pitchTypeColor',
                                signal: "showCircles ? parent.pitchResult == 'IP' && (isBrushedOrSelected || (!selectedIds && !brushedIds)) ? parent[colorField] : 'null' : null",
                            },
                            fillOpacity: {
                                signal: 'showCircles ? (hoveredId && hoveredId == parent.id ? 1 : 0.5) : 0',
                            },
                            stroke: {
                                scale: 'pitchTypeColor',
                                signal: "showCircles && parent.pitchResult != 'IP' && (isBrushedOrSelected || (!selectedIds && !brushedIds)) ? parent.pitchType : null",
                            },
                            strokeWidth: { value: 2 },
                            strokeOpacity: {
                                signal: "showCircles && parent.pitchResult != 'IP' ? (hoveredId && hoveredId == parent.id ? 1 : 0.5) : 0",
                            },
                            cursor: { signal: "isSelectable ? 'pointer' : 'default'" },
                            tooltip: { signal: 'isInteractive && !isHidden ? parent : null' },
                        },
                    },
                },
            ],
        },
        {
            name: 'brush',
            type: 'rect',
            interactive: false,
            encode: {
                enter: {
                    fill: { value: '#0080FF' },
                    fillOpacity: { value: 0.1 },
                    strokeWidth: { value: 1 },
                    stroke: { value: '#0080FF' },
                    strokeOpacity: { value: 0.3 },
                },
                update: {
                    x: { signal: 'brushStart ? brushStart.x : null' },
                    x2: { signal: 'brushEnd.x' },
                    y: { signal: 'brushStart ? brushStart.y : null' },
                    y2: { signal: 'brushEnd.y' },
                    fillOpacity: { signal: 'brushed ? 0.1 : 0' },
                    strokeOpacity: { signal: 'brushed ? 0.3 : 0' },
                },
            },
        },
    ],
};
