import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import type { View } from 'vega';

import { StlcI18nModule } from '@stlc/i18n/core';
import { assign, find, forEach, head, map } from '@stlc/lodash';
import { getStatSelectOption } from '@stlc/lookup/stat';
import { PlayerGamePitchFragment } from '@stlc/player-charts/data-access';
import { StlcSelectOption, StlcStat, StlcStatSelectOption } from '@stlc/shared';
import { UiChipSelectComponent } from '@stlc/ui';
import { UiVegaChartComponent } from '@stlc/ui/vega';
import { UserService } from '@stlc/user';

import { gamePitches, pitchMovementSpec, pitchRelease, strikeZoneSpec } from '../../specs';
import { PlayerChartsUiGamesPitchingService } from '../games-pitching-container/games-pitching.service';
import { PlayerChartsUiPitchingTooltipComponent } from '../pitching-tooltip';

@Component({
    selector: 'player-charts-ui-games-pitching-charts',
    templateUrl: './games-pitching-charts.component.html',
    styleUrls: ['./games-pitching-charts.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        StlcI18nModule,
        UiChipSelectComponent,
        UiVegaChartComponent,
        PlayerChartsUiPitchingTooltipComponent,
    ],
})
export class PlayerChartsUiGamesPitchingChartsComponent implements OnChanges, OnDestroy {
    chartOptions: StlcSelectOption[] = [
        {
            value: 'pitchRelease',
            text: 'Release',
            textKey: 'chart:release_label',
            selected: false,
        },
        {
            value: 'pitchMovement',
            text: 'Movement',
            textKey: 'chart:movement_label',
            selected: false,
        },
        {
            value: 'location',
            text: 'Location',
            textKey: 'chart:location_label',
            selected: true,
        },
    ];

    readonly perspectiveOptions: StlcSelectOption[] = [
        {
            text: "Pitcher's Perspective",
            textKey: 'chart:pitcherPerspective_label',
            value: 'pitcher',
        },
        {
            text: "Batter's Perspective",
            textKey: 'chart:batterPerspective_label',
            value: 'batter',
        },
    ];
    selectedPerspective?: StlcSelectOption;

    selectedChart = find(this.chartOptions, 'selected');

    @Input()
    playerThrows?: string;

    @Input()
    set pitches(values: PlayerGamePitchFragment) {
        this.data = map(values, (pitch, index) => assign(pitch, { pitchNumber: index + 1 }));
    }

    @Input()
    filteredIds: string[] | null = null;

    @Input()
    enableBrushing = false;

    private readonly defaultYAxis = getStatSelectOption(StlcStat.ReleaseSpeed);

    charts = {
        gamePitches: {
            spec: gamePitches,
            signals: {
                yAxis: this.defaultYAxis,
                showInningText: true,
                pitchTypes: null,
            },
        },
        pitchRelease: {
            spec: pitchRelease,
        },
        strikeZone: {
            spec: strikeZoneSpec,
        },
        pitchMovement: {
            spec: pitchMovementSpec,
        },
    };

    data: PlayerGamePitchFragment[] = [];
    gamePitchesView?: View;
    pitchReleaseView?: View;
    pitchMovementView?: View;
    strikeZoneView?: View;

    readonly service = inject(PlayerChartsUiGamesPitchingService);
    private readonly userService = inject(UserService);
    private readonly router = inject(Router);
    private readonly route = inject(ActivatedRoute);

    private destroy = new Subject<void>();

    constructor() {
        this.userService
            .getPreferenceValue$('player-charts:games:pitching:perspective')
            .pipe(first())
            .subscribe((value) => {
                forEach(this.perspectiveOptions, (option) => {
                    option.selected = value === option.value;
                });

                this.selectedPerspective =
                    this.perspectiveOptions.find(({ selected }) => selected) ?? head(this.perspectiveOptions);
            });

        this.service.selectedStatOption$.pipe(takeUntil(this.destroy)).subscribe((value) => {
            if (this.gamePitchesView) {
                this.gamePitchesView.signal('yAxis', value).runAsync();
            }
            this.charts.gamePitches.signals.yAxis = value ?? this.defaultYAxis;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['playerThrows']) {
            if (this.pitchReleaseView) {
                this.pitchReleaseView.signal('pitcherThrows', this.playerThrows).runAsync();
            }
            if (this.pitchMovementView) {
                this.pitchMovementView.signal('pitcherThrows', this.playerThrows).runAsync();
            }
        }

        if (changes['filteredIds']) {
            forEach(
                [this.gamePitchesView, this.pitchReleaseView, this.pitchMovementView, this.strikeZoneView],
                (view) => {
                    if (view) {
                        view.signal('filteredIds', this.filteredIds).runAsync();
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }

    gamePitchesRendered(view: View): void {
        this.gamePitchesView = view;
        this.gamePitchesView.signal('filteredIds', this.filteredIds).runAsync();
    }

    pitchReleaseRendered(view: View): void {
        this.pitchReleaseView = view;
        this.pitchReleaseView
            .signal('filteredIds', this.filteredIds)
            .signal('pitcherThrows', this.playerThrows)
            .signal('perspective', this.selectedPerspective?.value)
            .runAsync();
    }

    pitchMovementRendered(view: View): void {
        this.pitchMovementView = view;
        this.pitchMovementView
            .signal('filteredIds', this.filteredIds)
            .signal('pitcherThrows', this.playerThrows)
            .signal('perspective', this.selectedPerspective?.value)
            .runAsync();
    }

    strikeZoneRendered(view: View): void {
        this.strikeZoneView = view;
        this.strikeZoneView
            .signal('filteredIds', this.filteredIds)
            .signal('perspective', this.selectedPerspective?.value)
            .runAsync();
    }

    statChange(selected: StlcStatSelectOption): void {
        const firstStat = head(this.service.statOptions);
        this.updateQueryParams({ stat: firstStat !== selected ? selected?.value : null });
    }

    chartChange(selected: StlcSelectOption): void {
        this.selectedChart = selected;
    }

    changePerspective(change: MatSelectChange) {
        this.selectedPerspective = change.value;
        const perspective = this.selectedPerspective?.value;
        forEach([this.pitchReleaseView, this.pitchMovementView, this.strikeZoneView], (view) => {
            if (view) {
                view.signal('perspective', perspective).runAsync();
            }
        });

        this.userService.updatePreference('player-charts:games:pitching:perspective', perspective);
    }

    async updateQueryParams(queryParams: Params = {}): Promise<void> {
        await this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
            replaceUrl: true,
        });
    }
}
