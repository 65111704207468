<div class="flex flex-col text-xs max-w-lg whitespace-nowrap" *ngIf="!!pitch">
    <div class="flex flex-col mb-1">
        <div class="font-bold text-center" *ngIf="showGamePitchNumber">
            PA #{{ pitch.atBatNumber }}, {{ 'common:pitch' | i18next }} #{{ pitch.atbatPitchNumber }}
        </div>
        <div class="font-bold text-center">
            {{ pitch.isTopInning ? 'Top' : 'Bottom' }} {{ pitch.inning | stlcNumberToOrdinal }}, {{ pitch.preBalls }} -
            {{ pitch.preStrikes }}, {{ 'game:out' | i18next : { count: pitch.preOuts } }}
        </div>
        <div class="font-bold text-center" *ngIf="showBatter && pitch.batter">
            {{ showBatter && showPitcher ? '' : 'vs' }} {{ pitch.batter.fullName ?? (pitch.batter | stlcPlayerName) }}
        </div>
        <div class="font-bold text-center" *ngIf="showPitcher && pitch.pitcher">
            vs {{ pitch.pitcher.fullName ?? (pitch.pitcher | stlcPlayerName) }}
        </div>
    </div>
    <div class="text-center">
        {{ pitch.pitchType | stlcI18nPitchType : 'legacy' }}
    </div>
    <div class="flex" *ngIf="pitch.releaseSpeed || pitch.releaseExtension">
        <div class="flex-1">
            {{ pitch.releaseSpeed | stlcNumberToFixed : { digits: 0, defaultText: '-', appendText: ' MPH' } }}
        </div>
        <div class="flex-1 text-right">{{ pitch.releaseExtension | stlcFeetAndInches : { input: 'feet' } }} Ext</div>
    </div>
    <div class="flex" *ngIf="pitch.releaseHeight || pitch.releaseSide">
        <div class="flex-1">{{ pitch.releaseHeight | stlcFeetAndInches : { input: 'feet' } }} Ht</div>
        <div class="flex-1 text-right">
            {{ pitch.releaseSide | stlcFeetAndInches : { input: 'feet' } }} {{ 'stat:releaseSide_abbr' | i18next }}
        </div>
    </div>
    <div class="flex" *ngIf="pitch.verticalApproachAngle || pitch.horizontalApproachAngle">
        <div class="flex-1">
            {{ pitch.releaseVerticalAngle | stlcNumberToFixed : { digits: 1, appendText: '˚' } }} VRA
        </div>
        <div class="flex-1 text-right">
            {{ pitch.releaseHorizontalAngle | stlcNumberToFixed : { digits: 1, appendText: '˚' } }} HRA
        </div>
    </div>
    <div class="flex" *ngIf="pitch.releaseSpinRate || pitch.releaseSpinAxis || pitch.movementSpinAxis">
        <div class="flex-1">
            {{
                pitch.releaseSpinRate
                    | stlcNumberToFixed
                        : {
                              digits: 0,
                              roundToNearest: 5,
                              appendText: ' RPM'
                          }
            }}
        </div>
        <div class="flex-1 text-right">
            {{ pitch.movementSpinAxis || pitch.releaseSpinAxis | stlcDegreeToClockFace }}
        </div>
    </div>
    <div class="flex" *ngIf="pitch.inducedVerticalBreak || pitch.horizontalBreak">
        <div class="flex-1">
            {{ pitch.inducedVerticalBreak | stlcFeetAndInches : { input: 'inches', output: 'inches' } }} IVB
        </div>
        <div class="flex-1 text-right">
            {{ pitch.horizontalBreak | stlcFeetAndInches : { input: 'inches', output: 'inches' } }} HB
        </div>
    </div>
    <div class="flex" *ngIf="pitch.verticalApproachAngle || pitch.horizontalApproachAngle">
        <div class="flex-1">
            {{ pitch.verticalApproachAngle | stlcNumberToFixed : { digits: 1, appendText: '˚' } }} VAA
        </div>
        <div class="flex-1 text-right">
            {{ pitch.horizontalApproachAngle | stlcNumberToFixed : { digits: 1, appendText: '˚' } }} HAA
        </div>
    </div>
    <div class="text-xs text-center mt-1">{{ pitch | stlcI18nGameEvent }}</div>
</div>
